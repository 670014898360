import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import {
  Flex,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Select as SelectTwo,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Drawer,
  DrawerContent,
  useDisclosure,
  DrawerHeader,
  Divider,
  Switch,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { getAcquirers } from '../../../redux/action/acquirer';
import { useToast } from '@chakra-ui/react';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { getProvider } from '../../../redux/action/products';
import {
  activeProject,
  getProjectById,
  getProjects,
  inactiveProject,
  registerProject,
  updateProject,
} from '../../../redux/action/projects';

const DataProcess = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      description: '',
      acquirerId: '',
      identifier: '',
      projectType: '',
      projectId: '',
      accountOrigination: '',
      countries: '',
      currencies: '',
      destinyType: '',
      settlementCurrencies: '',
    },
  });
  const EnableButtonTemplate = rowData => {
    return (
      <Switch
        mx={10}
        isChecked={rowData?.status === 'ACTIVE' ? true : false}
        onChange={() => handleEnable(rowData)}
      />
    );
  };
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [acquirers, setAcquirers] = useState('');
  const [selectedAcquirer, setSelectedAcquirer] = useState('');
  const [providers, setProviders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [elementCurrencies, setElementCurrencies] = useState([]);
  const [projectId, setProject] = useState('');
  const opCurrencies = [
    { value: 'AFN', label: 'AFN' },
    { value: 'ALL', label: 'ALL' },
    { value: 'DZD', label: 'DZD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'AOA', label: 'AOA' },
    { value: 'XCD', label: 'XCD' },
    { value: 'ARS', label: 'ARS' },
    { value: 'AMD', label: 'AMD' },
    { value: 'AUD', label: 'AUD' },
    { value: 'AZN', label: 'AZN' },
    { value: 'BSD', label: 'BSD' },
    { value: 'BHD', label: 'BHD' },
    { value: 'BDT', label: 'BDT' },
    { value: 'BBD', label: 'BBD' },
    { value: 'BYN', label: 'BYN' },
    { value: 'BZD', label: 'BZD' },
    { value: 'XOF', label: 'XOF' },
    { value: 'BMD', label: 'BMD' },
    { value: 'BTN', label: 'BTN' },
    { value: 'BOB', label: 'BOB' },
    { value: 'BAM', label: 'BAM' },
    { value: 'BWP', label: 'BWP' },
    { value: 'BRL', label: 'BRL' },
    { value: 'BND', label: 'BND' },
    { value: 'BGN', label: 'BGN' },
    { value: 'BIF', label: 'BIF' },
    { value: 'CVE', label: 'CVE' },
    { value: 'KHR', label: 'KHR' },
    { value: 'XAF', label: 'XAF' },
    { value: 'CAD', label: 'CAD' },
    { value: 'CLP', label: 'CLP' },
    { value: 'CNY', label: 'CNY' },
    { value: 'COP', label: 'COP' },
    { value: 'CRC', label: 'CRC' },
    { value: 'CZK', label: 'CZK' },
    { value: 'DKK', label: 'DKK' },
    { value: 'DJF', label: 'DJF' },
    { value: 'DOP', label: 'DOP' },
    { value: 'USD', label: 'USD' },
    { value: 'EGP', label: 'EGP' },
    { value: 'ERN', label: 'ERN' },
    { value: 'ETB', label: 'ETB' },
    { value: 'FJD', label: 'FJD' },
    { value: 'GMD', label: 'GMD' },
    { value: 'GEL', label: 'GEL' },
    { value: 'GHS', label: 'GHS' },
    { value: 'GTQ', label: 'GTQ' },
    { value: 'GNF', label: 'GNF' },
    { value: 'GYD', label: 'GYD' },
    { value: 'HTG', label: 'HTG' },
    { value: 'HNL', label: 'HNL' },
    { value: 'HKD', label: 'HKD' },
    { value: 'HUF', label: 'HUF' },
    { value: 'ISK', label: 'ISK' },
    { value: 'INR', label: 'INR' },
    { value: 'IDR', label: 'IDR' },
    { value: 'ILS', label: 'ILS' },
    { value: 'JMD', label: 'JMD' },
    { value: 'JPY', label: 'JPY' },
    { value: 'JOD', label: 'JOD' },
    { value: 'KZT', label: 'KZT' },
    { value: 'KES', label: 'KES' },
    { value: 'KWD', label: 'KWD' },
    { value: 'KGS', label: 'KGS' },
    { value: 'LAK', label: 'LAK' },
    { value: 'LBP', label: 'LBP' },
    { value: 'LSL', label: 'LSL' },
    { value: 'LRD', label: 'LRD' },
    { value: 'CHF', label: 'CHF' },
    { value: 'MOP', label: 'MOP' },
    { value: 'MKD', label: 'MKD' },
    { value: 'MGA', label: 'MGA' },
    { value: 'MWK', label: 'MWK' },
    { value: 'MYR', label: 'MYR' },
    { value: 'MVR', label: 'MVR' },
    { value: 'MRU', label: 'MRU' },
    { value: 'MUR', label: 'MUR' },
    { value: 'MXN', label: 'MXN' },
    { value: 'MDL', label: 'MDL' },
    { value: 'MNT', label: 'MNT' },
    { value: 'MAD', label: 'MAD' },
    { value: 'MZN', label: 'MZN' },
    { value: 'MMK', label: 'MMK' },
    { value: 'NAD', label: 'NAD' },
    { value: 'NPR', label: 'NPR' },
    { value: 'NZD', label: 'NZD' },
    { value: 'NIO', label: 'NIO' },
    { value: 'NGN', label: 'NGN' },
    { value: 'NOK', label: 'NOK' },
    { value: 'OMR', label: 'OMR' },
    { value: 'PKR', label: 'PKR' },
    { value: 'PAB', label: 'PAB' },
    { value: 'PGK', label: 'PGK' },
    { value: 'PYG', label: 'PYG' },
    { value: 'PEN', label: 'PEN' },
    { value: 'PHP', label: 'PHP' },
    { value: 'PLN', label: 'PLN' },
    { value: 'QAR', label: 'QAR' },
    { value: 'RON', label: 'RON' },
    { value: 'RUB', label: 'RUB' },
    { value: 'RWF', label: 'RWF' },
    { value: 'SAR', label: 'SAR' },
    { value: 'RSD', label: 'RSD' },
    { value: 'SGD', label: 'SGD' },
    { value: 'ZAR', label: 'ZAR' },
    { value: 'KRW', label: 'KRW' },
    { value: 'SSP', label: 'SSP' },
    { value: 'LKR', label: 'LKR' },
    { value: 'SDG', label: 'SDG' },
    { value: 'SRD', label: 'SRD' },
    { value: 'SEK', label: 'SEK' },
    { value: 'SYP', label: 'SYP' },
    { value: 'TWD', label: 'TWD' },
    { value: 'TJS', label: 'TJS' },
    { value: 'TZS', label: 'TZS' },
    { value: 'THB', label: 'THB' },
    { value: 'TTD', label: 'TTD' },
    { value: 'TND', label: 'TND' },
    { value: 'TRY', label: 'TRY' },
    { value: 'UGX', label: 'UGX' },
    { value: 'UAH', label: 'UAH' },
    { value: 'AED', label: 'AED' },
    { value: 'UYU', label: 'UYU' },
    { value: 'UZS', label: 'UZS' },
    { value: 'VES', label: 'VES' },
    { value: 'VND', label: 'VND' },
    { value: 'YER', label: 'YER' },
    { value: 'ZMW', label: 'ZMW' },
    { value: 'ZWL', label: 'ZWL' },
  ];

  const opSetElement = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
  ];

  const opCountries = [
    { value: 'AND', label: t('countryCodes.AND') },
    { value: 'ARG', label: t('countryCodes.ARG') },
    { value: 'AUT', label: t('countryCodes.AUT') },
    { value: 'BEL', label: t('countryCodes.BEL') },
    { value: 'CHL', label: t('countryCodes.CHL') },
    { value: 'CHN', label: t('countryCodes.CHN') },
    { value: 'COL', label: t('countryCodes.COL') },
    { value: 'CYP', label: t('countryCodes.CYP') },
    { value: 'CZE', label: t('countryCodes.CZE') },
    { value: 'DNK', label: t('countryCodes.DNK') },
    { value: 'EST', label: t('countryCodes.EST') },
    { value: 'FIN', label: t('countryCodes.FIN') },
    { value: 'FRA', label: t('countryCodes.FRA') },
    { value: 'DEU', label: t('countryCodes.DEU') },
    { value: 'GRC', label: t('countryCodes.GRC') },
    { value: 'HUN', label: t('countryCodes.HUN') },
    { value: 'ISL', label: t('countryCodes.ISL') },
    { value: 'ITA', label: t('countryCodes.ITA') },
    { value: 'LVA', label: t('countryCodes.LVA') },
    { value: 'LIE', label: t('countryCodes.LIE') },
    { value: 'LTU', label: t('countryCodes.LTU') },
    { value: 'LUX', label: t('countryCodes.LUX') },
    { value: 'MLT', label: t('countryCodes.MLT') },
    { value: 'NLD', label: t('countryCodes.NLD') },
    { value: 'NOR', label: t('countryCodes.NOR') },
    { value: 'PRY', label: t('countryCodes.PRY') },
    { value: 'PER', label: t('countryCodes.PER') },
    { value: 'POL', label: t('countryCodes.POL') },
    { value: 'PRT', label: t('countryCodes.PRT') },
    { value: 'IRL', label: t('countryCodes.IRL') },
    { value: 'ROU', label: t('countryCodes.ROU') },
    { value: 'SMR', label: t('countryCodes.SMR') },
    { value: 'SVK', label: t('countryCodes.SVK') },
    { value: 'SVN', label: t('countryCodes.SVN') },
    { value: 'ESP', label: t('countryCodes.ESP') },
    { value: 'SWE', label: t('countryCodes.SWE') },
    { value: 'CHE', label: t('countryCodes.CHE') },
    { value: 'GBR', label: t('countryCodes.GBR') },
    { value: 'USA', label: t('countryCodes.USA') },
    { value: 'URY', label: t('countryCodes.URY') },
    { value: 'AFG', label: t('countryCodes.AFG') },
    { value: 'ALB', label: t('countryCodes.ALB') },
    { value: 'DZA', label: t('countryCodes.DZA') },
    { value: 'ASM', label: t('countryCodes.ASM') },
    { value: 'AGO', label: t('countryCodes.AGO') },
    { value: 'AIA', label: t('countryCodes.AIA') },
    { value: 'ATA', label: t('countryCodes.ATA') },
    { value: 'ATG', label: t('countryCodes.ATG') },
    { value: 'ARM', label: t('countryCodes.ARM') },
    { value: 'ABW', label: t('countryCodes.ABW') },
    { value: 'AUS', label: t('countryCodes.AUS') },
    { value: 'AZE', label: t('countryCodes.AZE') },
    { value: 'BHS', label: t('countryCodes.BHS') },
    { value: 'BHR', label: t('countryCodes.BHR') },
    { value: 'BGD', label: t('countryCodes.BGD') },
    { value: 'BRB', label: t('countryCodes.BRB') },
    { value: 'BLR', label: t('countryCodes.BLR') },
    { value: 'BLZ', label: t('countryCodes.BLZ') },
    { value: 'BEN', label: t('countryCodes.BEN') },
    { value: 'BMU', label: t('countryCodes.BMU') },
    { value: 'BES', label: t('countryCodes.BES') },
    { value: 'BTN', label: t('countryCodes.BTN') },
    { value: 'BOL', label: t('countryCodes.BOL') },
    { value: 'BIH', label: t('countryCodes.BIH') },
    { value: 'BWA', label: t('countryCodes.BWA') },
    { value: 'BVT', label: t('countryCodes.BVT') },
    { value: 'BRA', label: t('countryCodes.BRA') },
    { value: 'IOT', label: t('countryCodes.IOT') },
    { value: 'VGB', label: t('countryCodes.VGB') },
    { value: 'BRN', label: t('countryCodes.BRN') },
    { value: 'BGR', label: t('countryCodes.BGR') },
    { value: 'BFA', label: t('countryCodes.BFA') },
    { value: 'BDI', label: t('countryCodes.BDI') },
    { value: 'KHM', label: t('countryCodes.KHM') },
    { value: 'CMR', label: t('countryCodes.CMR') },
    { value: 'CAN', label: t('countryCodes.CAN') },
    { value: 'CPV', label: t('countryCodes.CPV') },
    { value: 'CYM', label: t('countryCodes.CYM') },
    { value: 'CAF', label: t('countryCodes.CAF') },
    { value: 'TCD', label: t('countryCodes.TCD') },
    { value: 'CXR', label: t('countryCodes.CXR') },
    { value: 'CCK', label: t('countryCodes.CCK') },
    { value: 'COM', label: t('countryCodes.COM') },
    { value: 'COG', label: t('countryCodes.COG') },
    { value: 'COK', label: t('countryCodes.COK') },
    { value: 'CRI', label: t('countryCodes.CRI') },
    { value: 'CIV', label: t('countryCodes.CIV') },
    { value: 'HRV', label: t('countryCodes.HRV') },
    { value: 'CUW', label: t('countryCodes.CUW') },
    { value: 'COD', label: t('countryCodes.COD') },
    { value: 'DJI', label: t('countryCodes.DJI') },
    { value: 'DMA', label: t('countryCodes.DMA') },
    { value: 'ECU', label: t('countryCodes.ECU') },
    { value: 'EGY', label: t('countryCodes.EGY') },
    { value: 'SLV', label: t('countryCodes.SLV') },
    { value: 'GNQ', label: t('countryCodes.GNQ') },
    { value: 'ERI', label: t('countryCodes.ERI') },
    { value: 'SWZ', label: t('countryCodes.SWZ') },
    { value: 'ETH', label: t('countryCodes.ETH') },
    { value: 'FRO', label: t('countryCodes.FRO') },
    { value: 'FLK', label: t('countryCodes.FLK') },
    { value: 'FJI', label: t('countryCodes.FJI') },
    { value: 'FRA', label: t('countryCodes.FRA') },
    { value: 'GUF', label: t('countryCodes.GUF') },
    { value: 'PYF', label: t('countryCodes.PYF') },
    { value: 'ATF', label: t('countryCodes.ATF') },
    { value: 'GAB', label: t('countryCodes.GAB') },
    { value: 'GMB', label: t('countryCodes.GMB') },
    { value: 'GEO', label: t('countryCodes.GEO') },
    { value: 'GHA', label: t('countryCodes.GHA') },
    { value: 'GIB', label: t('countryCodes.GIB') },
    { value: 'GRL', label: t('countryCodes.GRL') },
    { value: 'GRD', label: t('countryCodes.GRD') },
    { value: 'GLP', label: t('countryCodes.GLP') },
    { value: 'GUM', label: t('countryCodes.GUM') },
    { value: 'GTM', label: t('countryCodes.GTM') },
    { value: 'GIN', label: t('countryCodes.GIN') },
    { value: 'GNB', label: t('countryCodes.GNB') },
    { value: 'GUY', label: t('countryCodes.GUY') },
    { value: 'HTI', label: t('countryCodes.HTI') },
    { value: 'HMD', label: t('countryCodes.HMD') },
    { value: 'HND', label: t('countryCodes.HND') },
    { value: 'HKG', label: t('countryCodes.HKG') },
    { value: 'IND', label: t('countryCodes.IND') },
    { value: 'IDN', label: t('countryCodes.IDN') },
    { value: 'IRQ', label: t('countryCodes.IRQ') },
    { value: 'ISR', label: t('countryCodes.ISR') },
    { value: 'JAM', label: t('countryCodes.JAM') },
    { value: 'JPN', label: t('countryCodes.JPN') },
    { value: 'JOR', label: t('countryCodes.JOR') },
    { value: 'KAZ', label: t('countryCodes.KAZ') },
    { value: 'KEN', label: t('countryCodes.KEN') },
    { value: 'KIR', label: t('countryCodes.KIR') },
    { value: 'KOZ', label: t('countryCodes.KOZ') },
    { value: 'KWT', label: t('countryCodes.KWT') },
    { value: 'KGZ', label: t('countryCodes.KGZ') },
    { value: 'LAO', label: t('countryCodes.LAO') },
    { value: 'LBN', label: t('countryCodes.LBN') },
    { value: 'LSO', label: t('countryCodes.LSO') },
    { value: 'LBR', label: t('countryCodes.LBR') },
    { value: 'MAC', label: t('countryCodes.MAC') },
    { value: 'MKD', label: t('countryCodes.MKD') },
    { value: 'MDG', label: t('countryCodes.MDG') },
    { value: 'MWI', label: t('countryCodes.MWI') },
    { value: 'MYS', label: t('countryCodes.MYS') },
    { value: 'MDV', label: t('countryCodes.MDV') },
    { value: 'MLI', label: t('countryCodes.MLI') },
    { value: 'MHL', label: t('countryCodes.MHL') },
    { value: 'MTQ', label: t('countryCodes.MTQ') },
    { value: 'MRT', label: t('countryCodes.MRT') },
    { value: 'MUS', label: t('countryCodes.MUS') },
    { value: 'MYT', label: t('countryCodes.MYT') },
    { value: 'MEX', label: t('countryCodes.MEX') },
    { value: 'FSM', label: t('countryCodes.FSM') },
    { value: 'MDA', label: t('countryCodes.MDA') },
    { value: 'MCO', label: t('countryCodes.MCO') },
    { value: 'MNG', label: t('countryCodes.MNG') },
    { value: 'MNE', label: t('countryCodes.MNE') },
    { value: 'MSR', label: t('countryCodes.MSR') },
    { value: 'MAR', label: t('countryCodes.MAR') },
    { value: 'MOZ', label: t('countryCodes.MOZ') },
    { value: 'MMR', label: t('countryCodes.MMR') },
    { value: 'NAM', label: t('countryCodes.NAM') },
    { value: 'NRU', label: t('countryCodes.NRU') },
    { value: 'NPL', label: t('countryCodes.NPL') },
    { value: 'NCL', label: t('countryCodes.NCL') },
    { value: 'NZL', label: t('countryCodes.NZL') },
    { value: 'NIC', label: t('countryCodes.NIC') },
    { value: 'NER', label: t('countryCodes.NER') },
    { value: 'NGA', label: t('countryCodes.NGA') },
    { value: 'NIU', label: t('countryCodes.NIU') },
    { value: 'NFK', label: t('countryCodes.NFK') },
    { value: 'MNP', label: t('countryCodes.MNP') },
    { value: 'OMN', label: t('countryCodes.OMN') },
    { value: 'PAK', label: t('countryCodes.PAK') },
    { value: 'PLW', label: t('countryCodes.PLW') },
    { value: 'PAN', label: t('countryCodes.PAN') },
    { value: 'PNG', label: t('countryCodes.PNG') },
    { value: 'PHL', label: t('countryCodes.PHL') },
    { value: 'PCN', label: t('countryCodes.PCN') },
    { value: 'PRI', label: t('countryCodes.PRI') },
    { value: 'QAT', label: t('countryCodes.QAT') },
    { value: 'DOM', label: t('countryCodes.DOM') },
    { value: 'SRB', label: t('countryCodes.SRB') },
    { value: 'REU', label: t('countryCodes.REU') },
    { value: 'RWA', label: t('countryCodes.RWA') },
    { value: 'WSM', label: t('countryCodes.WSM') },
    { value: 'STP', label: t('countryCodes.STP') },
    { value: 'SAU', label: t('countryCodes.SAU') },
    { value: 'SEN', label: t('countryCodes.SEN') },
    { value: 'SYC', label: t('countryCodes.SYC') },
    { value: 'SGS', label: t('countryCodes.SGS') },
    { value: 'SLE', label: t('countryCodes.SLE') },
    { value: 'SGP', label: t('countryCodes.SGP') },
    { value: 'SXM', label: t('countryCodes.SXM') },
    { value: 'SLB', label: t('countryCodes.SLB') },
    { value: 'SOM', label: t('countryCodes.SOM') },
    { value: 'ZAF', label: t('countryCodes.ZAF') },
    { value: 'KOR', label: t('countryCodes.KOR') },
    { value: 'SSD', label: t('countryCodes.SSD') },
    { value: 'LKA', label: t('countryCodes.LKA') },
    { value: 'LBY', label: t('countryCodes.LBY') },
    { value: 'PSE', label: t('countryCodes.PSE') },
    { value: 'SHN', label: t('countryCodes.SHN') },
    { value: 'KNA', label: t('countryCodes.KNA') },
    { value: 'LCA', label: t('countryCodes.LCA') },
    { value: 'SPM', label: t('countryCodes.SPM') },
    { value: 'VCT', label: t('countryCodes.VCT') },
    { value: 'SDN', label: t('countryCodes.SDN') },
    { value: 'SUR', label: t('countryCodes.SUR') },
    { value: 'SJM', label: t('countryCodes.SJM') },
    { value: 'SYR', label: t('countryCodes.SYR') },
    { value: 'TWN', label: t('countryCodes.TWN') },
    { value: 'TJK', label: t('countryCodes.TJK') },
    { value: 'TZA', label: t('countryCodes.TZA') },
    { value: 'THA', label: t('countryCodes.THA') },
    { value: 'TLS', label: t('countryCodes.TLS') },
    { value: 'TGO', label: t('countryCodes.TGO') },
    { value: 'TKL', label: t('countryCodes.TKL') },
    { value: 'TON', label: t('countryCodes.TON') },
    { value: 'TTO', label: t('countryCodes.TTO') },
    { value: 'TUN', label: t('countryCodes.TUN') },
    { value: 'TUR', label: t('countryCodes.TUR') },
    { value: 'TKM', label: t('countryCodes.TKM') },
    { value: 'TCA', label: t('countryCodes.TCA') },
    { value: 'TUV', label: t('countryCodes.TUV') },
    { value: 'UGA', label: t('countryCodes.UGA') },
    { value: 'UKR', label: t('countryCodes.UKR') },
    { value: 'ARE', label: t('countryCodes.ARE') },
    { value: 'UMI', label: t('countryCodes.UMI') },
    { value: 'URY', label: t('countryCodes.URY') },
    { value: 'UZB', label: t('countryCodes.UZB') },
    { value: 'VUT', label: t('countryCodes.VUT') },
    { value: 'VAT', label: t('countryCodes.VAT') },
    { value: 'VEN', label: t('countryCodes.VEN') },
    { value: 'VNM', label: t('countryCodes.VNM') },
    { value: 'VIR', label: t('countryCodes.VIR') },
    { value: 'WLF', label: t('countryCodes.WLF') },
    { value: 'ESH', label: t('countryCodes.ESH') },
    { value: 'YEM', label: t('countryCodes.YEM') },
    { value: 'ZMB', label: t('countryCodes.ZMB') },
    { value: 'ZWE', label: t('countryCodes.ZWE') },
  ];
  useEffect(() => {
    const acquirers = async () => {
      try {
        let data = await dispatch(getAcquirers()).unwrap();
        setAcquirers(data);
      } catch (error) {}
    };
    acquirers();
  }, []);

  const handleChangeAcquirer = e => {
    let acq = acquirers.find(acquirer => acquirer.id === e.target.value);
    setSelectedAcquirer(acq);
  };

  const EditButtonTemplate = rowData => {
    return (
      <EditIcon
        marginLeft={5}
        cursor={'pointer'}
        onClick={() => handleEdit(rowData)}
      />
    );
  };

  const ViewButtonTemplate = rowData => {
    return (
      <SearchIcon
        marginLeft={8}
        cursor={'pointer'}
        onClick={() => handleView(rowData)}
      />
    );
  };

  useEffect(() => {
    const projects = async () => {
      try {
        let data = await dispatch(getProjects()).unwrap();
        setProjects(data);
        onClose();
      } catch (error) {}
    };
    projects();
  }, []);

  const columns = [
    { field: 'description', header: 'projectName', sortable: true },
    { field: 'acquirerId', header: 'acquirer', sortable: true },
    { field: 'providerId', header: 'provider', sortable: true },
    { field: 'projectType', header: 'type', sortable: true },
    {
      field: 'iconEnable',
      color: 'black',
      header: 'iconEnable',
      sortable: false,
      body: rowData => EnableButtonTemplate(rowData),
    },
    {
      field: 'iconEdit',
      color: 'black',
      header: 'iconEdit',
      sortable: false,
      body: EditButtonTemplate,
    },

    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];

  async function onSubmit(values) {
    values = {
      ...values,
      countries: countries,
      settlementCurrencies: elementCurrencies,
    };
    const resp = await dispatch(registerProject(values)).unwrap();
    onClose();
    handleProjects();
  }

  async function onUpdate(values) {
    values = {
      id: projectId,
      currencies: currencies.map(c => c.value),
      countries: countries.map(c => c.value),
      settlementCurrencies: elementCurrencies.map(e => e.value),
    };
    const resp = await dispatch(updateProject(values)).unwrap();
    onEditClose();
  }

  const handleEnable = async data => {
    if (data.status === 'ACTIVE') {
      await dispatch(inactiveProject(data.id)).unwrap();
    } else {
      await dispatch(activeProject(data.id)).unwrap();
    }
    let proj = await dispatch(getProjects()).unwrap();
    setProjects(proj);
  };

  const handleOnOpen = async e => {
    reset({
      description: '',
      acquirerId: '',
      providerId: '',
      projectType: '',
      identifier: '',
      accountOrigination: '',
      countries: '',
      currencies: '',
      destinyType: '',
      settlementCurrencies: '',
    });
    onOpen();
  };

  const handleView = async data => {
    const resp = await dispatch(getProjectById(data.id)).unwrap();
    setIsView(true);
    setIsEdit(false);
    reset({
      description: resp.description,
      acquirerId: resp.providerId,
      providerId: resp.acquirerId,
      projectType: resp.projectType,
      identifier: resp.identifier,
      accountOrigination: resp.accountOrigination,
      countries: resp.countries.join(', '),
      currencies: resp.currencies,
      destinyType: resp.destinyType,
      settlementCurrencies: resp.settlementCurrencies,
    });
    setCountries(resp.countries.join(', '));
    setElementCurrencies(resp.settlementCurrencies.join(', '));
    setCurrencies(resp.currencies.join(', '));
    onEditOpen();
  };

  const handleEdit = async data => {
    const resp = await dispatch(getProjectById(data.id)).unwrap();
    setProject(data.id);
    setIsEdit(true);
    setIsView(false);
    const formattedValues = resp.currencies.map(currency =>
      opCurrencies.find(option => option.value === currency)
    );
    const formattedElement = resp.settlementCurrencies.map(s =>
      opSetElement.find(op => op.value === s)
    );
    const formattedCountries = resp.countries.map(c =>
      opCountries.find(Opc => Opc.value === c)
    );
    setCurrencies(formattedValues);
    setElementCurrencies(formattedElement);
    setCountries(formattedCountries);
    reset({
      description: resp.description,
      acquirerId: resp.providerId,
      providerId: resp.acquirerId,
      projectType: resp.projectType,
      identifier: resp.identifier,
      accountOrigination: resp.accountOrigination,
      countries: formattedCountries,
      currencies: formattedValues,
      destinyType: resp.destinyType,
      settlementCurrencies: formattedElement,
    });
    onEditOpen();
  };

  useEffect(() => {
    const providers = async () => {
      try {
        const data = await dispatch(getProvider()).unwrap();
        setProviders(data);
      } catch (error) {}
    };
    providers();
  }, []);

  const handleCurrencies = async e => {
    let list = e.map(c => c.value);
    reset({
      currencies: list,
    });
  };

  const handleCountries = async e => {
    let list = e.map(c => c.value);
    setCountries(list);
  };

  const handleCurrency = async e => {
    let list = e.map(c => c.value);
    setElementCurrencies(list);
  };

  const handleModalClose = async () => {};

  const handelCloseVisualize = async () => {};

  const handleProjects = async () => {
    let data = await dispatch(getProjects()).unwrap();
    setProjects(data);
  };

  const handleChangeCountries = selectedOptions => {
    setCountries(selectedOptions);
  };

  const handleCurrenciesChange = selectedOptions => {
    setCurrencies(selectedOptions);
  };

  const handleElementChange = selectedOptions => {
    setElementCurrencies(selectedOptions);
  };

  return (
    <>
      <Flex direction="column" alignItems="start" textAlign="center">
        <Button
          my={2}
          onClick={handleOnOpen}
          variant="outline"
          boxShadow="0px 8px 15px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)"
        >
          {t('projects.addNew')} +
        </Button>

        <Divider my={8} borderColor={'black'} />

        <Drawer
          placement="right"
          isOpen={isEditOpen}
          onClose={handelCloseVisualize}
          returnFocusOnClose={false}
          onOverlayClick={onEditClose}
          size={'xl'}
        >
          <DrawerContent overflowY="auto" backgroundColor={'white'}>
            <DrawerHeader color={'black'} borderBottomWidth="1px">
              {isView === true ? t('projects.visualize') : t('projects.update')}
            </DrawerHeader>
            <form onSubmit={handleSubmit(onUpdate)}>
              <Box m={3}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={!!errors.acquirerId}>
                    <FormLabel>Acquirer:</FormLabel>
                    <Input
                      {...register('acquirerId')}
                      id="acquirerId"
                      isDisabled={true}
                    />
                  </FormControl>

                  <FormControl isInvalid={!!errors.projectType}>
                    <FormLabel>{t('octUnitary.formUnitary.type')}:</FormLabel>
                    <Input
                      {...register('projectType')}
                      id="projectType"
                      isDisabled={true}
                    />
                  </FormControl>
                </Grid>
                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.providerId}>
                    <FormLabel>{t('projects.provider')}:</FormLabel>

                    <Input
                      {...register('providerId')}
                      id="providerId"
                      isDisabled={true}
                    ></Input>
                  </FormControl>

                  <FormControl isInvalid={errors.destinyType}>
                    <FormLabel>{t('projects.destination')}:</FormLabel>

                    <Input
                      {...register('destinyType')}
                      id="destinyType"
                      isDisabled={true}
                    ></Input>
                  </FormControl>
                </Grid>
                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>{t('projects.description')}:</FormLabel>
                    <Input
                      id="description"
                      isDisabled={true}
                      {...register('description')}
                    ></Input>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.identifier}>
                    <FormLabel>{t('projects.projectId')}:</FormLabel>
                    <Input
                      id="identifier"
                      isDisabled={true}
                      {...register('identifier')}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.accountOrigination}>
                    <FormLabel>{t('projects.accountNumber')}:</FormLabel>
                    <Input
                      id="accountOrigination"
                      isDisabled={true}
                      {...register('accountOrigination')}
                    />
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.countries}>
                    <FormLabel>{t('projects.countries')}:</FormLabel>

                    {isView && (
                      <Input
                        value={countries}
                        id="countries"
                        isDisabled={true}
                      />
                    )}

                    {isEdit && (
                      <Select
                        isMulti
                        id="countries"
                        options={opCountries}
                        value={countries}
                        onChange={handleChangeCountries}
                        placeholder={t('placeholders.option')}
                        closeMenuOnSelect={false}
                        size="sm"
                      />
                    )}

                    <FormErrorMessage>
                      {errors.countries && errors.countries.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.currencies}>
                    <FormLabel>{t('projects.currencies')}:</FormLabel>

                    {isView && (
                      <Input
                        value={currencies}
                        id="currencies"
                        isDisabled={true}
                      />
                    )}

                    {isEdit && (
                      <Select
                        isMulti
                        id="currencies"
                        options={opCurrencies}
                        value={currencies}
                        onChange={handleCurrenciesChange}
                        placeholder={t('placeholders.option')}
                        closeMenuOnSelect={false}
                        size="sm"
                      />
                    )}
                    <FormErrorMessage>
                      {errors.currencies && errors.currencies.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.settlementCurrencies}>
                    <FormLabel>{t('projects.settlementCurrencies')}:</FormLabel>
                    {isView && (
                      <Input
                        value={elementCurrencies}
                        id="currencies"
                        isDisabled={true}
                      />
                    )}
                    {isEdit && (
                      <Select
                        isMulti
                        id="settlementCurrency"
                        options={opSetElement}
                        value={elementCurrencies}
                        onChange={handleElementChange}
                        placeholder={t('placeholders.option')}
                        closeMenuOnSelect={false}
                        size="sm"
                      ></Select>
                    )}
                    <FormErrorMessage>
                      {errors.settlementCurrencies &&
                        errors.settlementCurrencies.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>
                <Divider borderColor="gray" my={4} />
              </Box>

              {isEdit && (
                <Box p={3} justifyContent={'end'} display={'flex'}>
                  <Button
                    variant="outline"
                    border={'1px solid #982549'}
                    color={'#982549'}
                    onClick={onClose}
                    mx={2}
                  >
                    {t('buttons.cancel')}
                  </Button>
                  <Button isLoading={isSubmitting} type="submit">
                    {t('buttons.confirm')}
                  </Button>
                </Box>
              )}
            </form>
          </DrawerContent>
        </Drawer>

        <Drawer
          placement="right"
          isOpen={isOpen}
          onClose={handleModalClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size={'xl'}
        >
          <DrawerContent overflowY="auto" backgroundColor={'white'}>
            <DrawerHeader color={'black'} borderBottomWidth="1px">
              {t('projects.createNew')}
            </DrawerHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box m={3}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.acquirerId}>
                    <FormLabel>Acquirer:</FormLabel>

                    <SelectTwo
                      {...register('acquirerId', {
                        required: 'Acquirer required',
                      })}
                      onChange={handleChangeAcquirer}
                      placeholder={t('placeholders.option')}
                      id="acquirerId"
                    >
                      {acquirers &&
                        acquirers.map(op => {
                          return (
                            <option key={op.id} value={op.id}>
                              {op.name}
                            </option>
                          );
                        })}
                    </SelectTwo>

                    <FormErrorMessage>
                      {errors.acquirerId && errors.acquirerId.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.projectType}>
                    <FormLabel>{t('octUnitary.formUnitary.type')}:</FormLabel>

                    <SelectTwo
                      {...register('projectType', {
                        required: 'projectType required',
                      })}
                      onChange={handleChangeAcquirer}
                      placeholder={t('placeholders.option')}
                      id="projectType"
                    >
                      <option value={'ENVIO_DIRETO'}>Envio direto</option>
                    </SelectTwo>

                    <FormErrorMessage>
                      {errors.projectType && errors.projectType.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>
                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.providerId}>
                    <FormLabel>{t('projects.provider')}:</FormLabel>

                    <SelectTwo
                      id="providerId"
                      placeholder={t('placeholders.option')}
                      {...register('providerId', {
                        required: 'Provider is required',
                      })}
                    >
                      {providers
                        ? providers.map(pv => {
                            return (
                              <option key={pv.id} value={pv.id}>
                                {pv.name}{' '}
                              </option>
                            );
                          })
                        : ''}
                    </SelectTwo>
                    <FormErrorMessage>
                      {errors.providerId && errors.providerId.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.destinyType}>
                    <FormLabel>{t('projects.destination')}:</FormLabel>

                    <SelectTwo
                      {...register('destinyType', {
                        required: 'Destination required',
                      })}
                      placeholder={t('placeholders.option')}
                      id="destinyType"
                    >
                      <option value={'CARTAO'}>Cartão</option>
                      <option value={'CONTA'}>Conta</option>
                    </SelectTwo>
                    <FormErrorMessage>
                      {errors.destinyType && errors.destinyType.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>
                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>{t('projects.description')}:</FormLabel>
                    <Input
                      id="description"
                      {...register('description', {
                        required: 'Description required',
                      })}
                    ></Input>
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.identifier}>
                    <FormLabel>{t('projects.projectId')}:</FormLabel>
                    <Input
                      id="identifier"
                      {...register('identifier', {
                        required: 'Project Id required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.identifier && errors.identifier.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.accountOrigination}>
                    <FormLabel>{t('projects.accountNumber')}:</FormLabel>
                    <Input
                      id="accountOrigination"
                      {...register('accountOrigination', {
                        required: 'Account Number required',
                      })}
                    />

                    <FormErrorMessage>
                      {errors.accountOrigination &&
                        errors.accountOrigination.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.countries}>
                    <FormLabel>{t('projects.countries')}:</FormLabel>
                    <Select
                      isMulti
                      id="countries"
                      onChange={handleCountries}
                      options={[
                        { value: 'AND', label: t('countryCodes.AND') },
                        { value: 'ARG', label: t('countryCodes.ARG') },
                        { value: 'AUT', label: t('countryCodes.AUT') },
                        { value: 'BEL', label: t('countryCodes.BEL') },
                        { value: 'CHL', label: t('countryCodes.CHL') },
                        { value: 'CHN', label: t('countryCodes.CHN') },
                        { value: 'COL', label: t('countryCodes.COL') },
                        { value: 'CYP', label: t('countryCodes.CYP') },
                        { value: 'CZE', label: t('countryCodes.CZE') },
                        { value: 'DNK', label: t('countryCodes.DNK') },
                        { value: 'EST', label: t('countryCodes.EST') },
                        { value: 'FIN', label: t('countryCodes.FIN') },
                        { value: 'FRA', label: t('countryCodes.FRA') },
                        { value: 'DEU', label: t('countryCodes.DEU') },
                        { value: 'GRC', label: t('countryCodes.GRC') },
                        { value: 'HUN', label: t('countryCodes.HUN') },
                        { value: 'ISL', label: t('countryCodes.ISL') },
                        { value: 'ITA', label: t('countryCodes.ITA') },
                        { value: 'LVA', label: t('countryCodes.LVA') },
                        { value: 'LIE', label: t('countryCodes.LIE') },
                        { value: 'LTU', label: t('countryCodes.LTU') },
                        { value: 'LUX', label: t('countryCodes.LUX') },
                        { value: 'MLT', label: t('countryCodes.MLT') },
                        { value: 'NLD', label: t('countryCodes.NLD') },
                        { value: 'NOR', label: t('countryCodes.NOR') },
                        { value: 'PRY', label: t('countryCodes.PRY') },
                        { value: 'PER', label: t('countryCodes.PER') },
                        { value: 'POL', label: t('countryCodes.POL') },
                        { value: 'PRT', label: t('countryCodes.PRT') },
                        { value: 'IRL', label: t('countryCodes.IRL') },
                        { value: 'ROU', label: t('countryCodes.ROU') },
                        { value: 'SMR', label: t('countryCodes.SMR') },
                        { value: 'SVK', label: t('countryCodes.SVK') },
                        { value: 'SVN', label: t('countryCodes.SVN') },
                        { value: 'ESP', label: t('countryCodes.ESP') },
                        { value: 'SWE', label: t('countryCodes.SWE') },
                        { value: 'CHE', label: t('countryCodes.CHE') },
                        { value: 'GBR', label: t('countryCodes.GBR') },
                        { value: 'USA', label: t('countryCodes.USA') },
                        { value: 'URY', label: t('countryCodes.URY') },
                        { value: 'AFG', label: t('countryCodes.AFG') },
                        { value: 'ALB', label: t('countryCodes.ALB') },
                        { value: 'DZA', label: t('countryCodes.DZA') },
                        { value: 'ASM', label: t('countryCodes.ASM') },
                        { value: 'AGO', label: t('countryCodes.AGO') },
                        { value: 'AIA', label: t('countryCodes.AIA') },
                        { value: 'ATA', label: t('countryCodes.ATA') },
                        { value: 'ATG', label: t('countryCodes.ATG') },
                        { value: 'ARM', label: t('countryCodes.ARM') },
                        { value: 'ABW', label: t('countryCodes.ABW') },
                        { value: 'AUS', label: t('countryCodes.AUS') },
                        { value: 'AZE', label: t('countryCodes.AZE') },
                        { value: 'BHS', label: t('countryCodes.BHS') },
                        { value: 'BHR', label: t('countryCodes.BHR') },
                        { value: 'BGD', label: t('countryCodes.BGD') },
                        { value: 'BRB', label: t('countryCodes.BRB') },
                        { value: 'BLR', label: t('countryCodes.BLR') },
                        { value: 'BLZ', label: t('countryCodes.BLZ') },
                        { value: 'BEN', label: t('countryCodes.BEN') },
                        { value: 'BMU', label: t('countryCodes.BMU') },
                        { value: 'BES', label: t('countryCodes.BES') },
                        { value: 'BTN', label: t('countryCodes.BTN') },
                        { value: 'BOL', label: t('countryCodes.BOL') },
                        { value: 'BIH', label: t('countryCodes.BIH') },
                        { value: 'BWA', label: t('countryCodes.BWA') },
                        { value: 'BVT', label: t('countryCodes.BVT') },
                        { value: 'BRA', label: t('countryCodes.BRA') },
                        { value: 'IOT', label: t('countryCodes.IOT') },
                        { value: 'VGB', label: t('countryCodes.VGB') },
                        { value: 'BRN', label: t('countryCodes.BRN') },
                        { value: 'BGR', label: t('countryCodes.BGR') },
                        { value: 'BFA', label: t('countryCodes.BFA') },
                        { value: 'BDI', label: t('countryCodes.BDI') },
                        { value: 'KHM', label: t('countryCodes.KHM') },
                        { value: 'CMR', label: t('countryCodes.CMR') },
                        { value: 'CAN', label: t('countryCodes.CAN') },
                        { value: 'CPV', label: t('countryCodes.CPV') },
                        { value: 'CYM', label: t('countryCodes.CYM') },
                        { value: 'CAF', label: t('countryCodes.CAF') },
                        { value: 'TCD', label: t('countryCodes.TCD') },
                        { value: 'CXR', label: t('countryCodes.CXR') },
                        { value: 'CCK', label: t('countryCodes.CCK') },
                        { value: 'COM', label: t('countryCodes.COM') },
                        { value: 'COG', label: t('countryCodes.COG') },
                        { value: 'COK', label: t('countryCodes.COK') },
                        { value: 'CRI', label: t('countryCodes.CRI') },
                        { value: 'CIV', label: t('countryCodes.CIV') },
                        { value: 'HRV', label: t('countryCodes.HRV') },
                        { value: 'CUW', label: t('countryCodes.CUW') },
                        { value: 'COD', label: t('countryCodes.COD') },
                        { value: 'DJI', label: t('countryCodes.DJI') },
                        { value: 'DMA', label: t('countryCodes.DMA') },
                        { value: 'ECU', label: t('countryCodes.ECU') },
                        { value: 'EGY', label: t('countryCodes.EGY') },
                        { value: 'SLV', label: t('countryCodes.SLV') },
                        { value: 'GNQ', label: t('countryCodes.GNQ') },
                        { value: 'ERI', label: t('countryCodes.ERI') },
                        { value: 'SWZ', label: t('countryCodes.SWZ') },
                        { value: 'ETH', label: t('countryCodes.ETH') },
                        { value: 'FRO', label: t('countryCodes.FRO') },
                        { value: 'FLK', label: t('countryCodes.FLK') },
                        { value: 'FJI', label: t('countryCodes.FJI') },
                        { value: 'FRA', label: t('countryCodes.FRA') },
                        { value: 'GUF', label: t('countryCodes.GUF') },
                        { value: 'PYF', label: t('countryCodes.PYF') },
                        { value: 'ATF', label: t('countryCodes.ATF') },
                        { value: 'GAB', label: t('countryCodes.GAB') },
                        { value: 'GMB', label: t('countryCodes.GMB') },
                        { value: 'GEO', label: t('countryCodes.GEO') },
                        { value: 'GHA', label: t('countryCodes.GHA') },
                        { value: 'GIB', label: t('countryCodes.GIB') },
                        { value: 'GRL', label: t('countryCodes.GRL') },
                        { value: 'GRD', label: t('countryCodes.GRD') },
                        { value: 'GLP', label: t('countryCodes.GLP') },
                        { value: 'GUM', label: t('countryCodes.GUM') },
                        { value: 'GTM', label: t('countryCodes.GTM') },
                        { value: 'GIN', label: t('countryCodes.GIN') },
                        { value: 'GNB', label: t('countryCodes.GNB') },
                        { value: 'GUY', label: t('countryCodes.GUY') },
                        { value: 'HTI', label: t('countryCodes.HTI') },
                        { value: 'HMD', label: t('countryCodes.HMD') },
                        { value: 'HND', label: t('countryCodes.HND') },
                        { value: 'HKG', label: t('countryCodes.HKG') },
                        { value: 'IND', label: t('countryCodes.IND') },
                        { value: 'IDN', label: t('countryCodes.IDN') },
                        { value: 'IRQ', label: t('countryCodes.IRQ') },
                        { value: 'ISR', label: t('countryCodes.ISR') },
                        { value: 'JAM', label: t('countryCodes.JAM') },
                        { value: 'JPN', label: t('countryCodes.JPN') },
                        { value: 'JOR', label: t('countryCodes.JOR') },
                        { value: 'KAZ', label: t('countryCodes.KAZ') },
                        { value: 'KEN', label: t('countryCodes.KEN') },
                        { value: 'KIR', label: t('countryCodes.KIR') },
                        { value: 'KOZ', label: t('countryCodes.KOZ') },
                        { value: 'KWT', label: t('countryCodes.KWT') },
                        { value: 'KGZ', label: t('countryCodes.KGZ') },
                        { value: 'LAO', label: t('countryCodes.LAO') },
                        { value: 'LBN', label: t('countryCodes.LBN') },
                        { value: 'LSO', label: t('countryCodes.LSO') },
                        { value: 'LBR', label: t('countryCodes.LBR') },
                        { value: 'MAC', label: t('countryCodes.MAC') },
                        { value: 'MKD', label: t('countryCodes.MKD') },
                        { value: 'MDG', label: t('countryCodes.MDG') },
                        { value: 'MWI', label: t('countryCodes.MWI') },
                        { value: 'MYS', label: t('countryCodes.MYS') },
                        { value: 'MDV', label: t('countryCodes.MDV') },
                        { value: 'MLI', label: t('countryCodes.MLI') },
                        { value: 'MHL', label: t('countryCodes.MHL') },
                        { value: 'MTQ', label: t('countryCodes.MTQ') },
                        { value: 'MRT', label: t('countryCodes.MRT') },
                        { value: 'MUS', label: t('countryCodes.MUS') },
                        { value: 'MYT', label: t('countryCodes.MYT') },
                        { value: 'MEX', label: t('countryCodes.MEX') },
                        { value: 'FSM', label: t('countryCodes.FSM') },
                        { value: 'MDA', label: t('countryCodes.MDA') },
                        { value: 'MCO', label: t('countryCodes.MCO') },
                        { value: 'MNG', label: t('countryCodes.MNG') },
                        { value: 'MNE', label: t('countryCodes.MNE') },
                        { value: 'MSR', label: t('countryCodes.MSR') },
                        { value: 'MAR', label: t('countryCodes.MAR') },
                        { value: 'MOZ', label: t('countryCodes.MOZ') },
                        { value: 'MMR', label: t('countryCodes.MMR') },
                        { value: 'NAM', label: t('countryCodes.NAM') },
                        { value: 'NRU', label: t('countryCodes.NRU') },
                        { value: 'NPL', label: t('countryCodes.NPL') },
                        { value: 'NCL', label: t('countryCodes.NCL') },
                        { value: 'NZL', label: t('countryCodes.NZL') },
                        { value: 'NIC', label: t('countryCodes.NIC') },
                        { value: 'NER', label: t('countryCodes.NER') },
                        { value: 'NGA', label: t('countryCodes.NGA') },
                        { value: 'NIU', label: t('countryCodes.NIU') },
                        { value: 'NFK', label: t('countryCodes.NFK') },
                        { value: 'MNP', label: t('countryCodes.MNP') },
                        { value: 'OMN', label: t('countryCodes.OMN') },
                        { value: 'PAK', label: t('countryCodes.PAK') },
                        { value: 'PLW', label: t('countryCodes.PLW') },
                        { value: 'PAN', label: t('countryCodes.PAN') },
                        { value: 'PNG', label: t('countryCodes.PNG') },
                        { value: 'PHL', label: t('countryCodes.PHL') },
                        { value: 'PCN', label: t('countryCodes.PCN') },
                        { value: 'PRI', label: t('countryCodes.PRI') },
                        { value: 'QAT', label: t('countryCodes.QAT') },
                        { value: 'DOM', label: t('countryCodes.DOM') },
                        { value: 'SRB', label: t('countryCodes.SRB') },
                        { value: 'REU', label: t('countryCodes.REU') },
                        { value: 'RWA', label: t('countryCodes.RWA') },
                        { value: 'WSM', label: t('countryCodes.WSM') },
                        { value: 'STP', label: t('countryCodes.STP') },
                        { value: 'SAU', label: t('countryCodes.SAU') },
                        { value: 'SEN', label: t('countryCodes.SEN') },
                        { value: 'SYC', label: t('countryCodes.SYC') },
                        { value: 'SGS', label: t('countryCodes.SGS') },
                        { value: 'SLE', label: t('countryCodes.SLE') },
                        { value: 'SGP', label: t('countryCodes.SGP') },
                        { value: 'SXM', label: t('countryCodes.SXM') },
                        { value: 'SLB', label: t('countryCodes.SLB') },
                        { value: 'SOM', label: t('countryCodes.SOM') },
                        { value: 'ZAF', label: t('countryCodes.ZAF') },
                        { value: 'KOR', label: t('countryCodes.KOR') },
                        { value: 'SSD', label: t('countryCodes.SSD') },
                        { value: 'LKA', label: t('countryCodes.LKA') },
                        { value: 'LBY', label: t('countryCodes.LBY') },
                        { value: 'PSE', label: t('countryCodes.PSE') },
                        { value: 'SHN', label: t('countryCodes.SHN') },
                        { value: 'KNA', label: t('countryCodes.KNA') },
                        { value: 'LCA', label: t('countryCodes.LCA') },
                        { value: 'SPM', label: t('countryCodes.SPM') },
                        { value: 'VCT', label: t('countryCodes.VCT') },
                        { value: 'SDN', label: t('countryCodes.SDN') },
                        { value: 'SUR', label: t('countryCodes.SUR') },
                        { value: 'SJM', label: t('countryCodes.SJM') },
                        { value: 'SYR', label: t('countryCodes.SYR') },
                        { value: 'TWN', label: t('countryCodes.TWN') },
                        { value: 'TJK', label: t('countryCodes.TJK') },
                        { value: 'TZA', label: t('countryCodes.TZA') },
                        { value: 'THA', label: t('countryCodes.THA') },
                        { value: 'TLS', label: t('countryCodes.TLS') },
                        { value: 'TGO', label: t('countryCodes.TGO') },
                        { value: 'TKL', label: t('countryCodes.TKL') },
                        { value: 'TON', label: t('countryCodes.TON') },
                        { value: 'TTO', label: t('countryCodes.TTO') },
                        { value: 'TUN', label: t('countryCodes.TUN') },
                        { value: 'TUR', label: t('countryCodes.TUR') },
                        { value: 'TKM', label: t('countryCodes.TKM') },
                        { value: 'TCA', label: t('countryCodes.TCA') },
                        { value: 'TUV', label: t('countryCodes.TUV') },
                        { value: 'UGA', label: t('countryCodes.UGA') },
                        { value: 'UKR', label: t('countryCodes.UKR') },
                        { value: 'ARE', label: t('countryCodes.ARE') },
                        { value: 'UMI', label: t('countryCodes.UMI') },
                        { value: 'URY', label: t('countryCodes.URY') },
                        { value: 'UZB', label: t('countryCodes.UZB') },
                        { value: 'VUT', label: t('countryCodes.VUT') },
                        { value: 'VAT', label: t('countryCodes.VAT') },
                        { value: 'VEN', label: t('countryCodes.VEN') },
                        { value: 'VNM', label: t('countryCodes.VNM') },
                        { value: 'VIR', label: t('countryCodes.VIR') },
                        { value: 'WLF', label: t('countryCodes.WLF') },
                        { value: 'ESH', label: t('countryCodes.ESH') },
                        { value: 'YEM', label: t('countryCodes.YEM') },
                        { value: 'ZMB', label: t('countryCodes.ZMB') },
                        { value: 'ZWE', label: t('countryCodes.ZWE') },
                      ]}
                      placeholder={t('placeholders.option')}
                      closeMenuOnSelect={false}
                      size="sm"
                    />
                    <FormErrorMessage>
                      {errors.countries && errors.countries.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.currencies}>
                    <FormLabel>{t('projects.currencies')}:</FormLabel>

                    <Select
                      isMulti
                      id="currencies"
                      onChange={handleCurrencies}
                      options={[
                        { value: 'AFN', label: 'AFN' },
                        { value: 'ALL', label: 'ALL' },
                        { value: 'DZD', label: 'DZD' },
                        { value: 'EUR', label: 'EUR' },
                        { value: 'AOA', label: 'AOA' },
                        { value: 'XCD', label: 'XCD' },
                        { value: 'ARS', label: 'ARS' },
                        { value: 'AMD', label: 'AMD' },
                        { value: 'AUD', label: 'AUD' },
                        { value: 'AZN', label: 'AZN' },
                        { value: 'BSD', label: 'BSD' },
                        { value: 'BHD', label: 'BHD' },
                        { value: 'BDT', label: 'BDT' },
                        { value: 'BBD', label: 'BBD' },
                        { value: 'BYN', label: 'BYN' },
                        { value: 'BZD', label: 'BZD' },
                        { value: 'XOF', label: 'XOF' },
                        { value: 'BMD', label: 'BMD' },
                        { value: 'BTN', label: 'BTN' },
                        { value: 'BOB', label: 'BOB' },
                        { value: 'BAM', label: 'BAM' },
                        { value: 'BWP', label: 'BWP' },
                        { value: 'BRL', label: 'BRL' },
                        { value: 'BND', label: 'BND' },
                        { value: 'BGN', label: 'BGN' },
                        { value: 'BIF', label: 'BIF' },
                        { value: 'CVE', label: 'CVE' },
                        { value: 'KHR', label: 'KHR' },
                        { value: 'XAF', label: 'XAF' },
                        { value: 'CAD', label: 'CAD' },
                        { value: 'CLP', label: 'CLP' },
                        { value: 'CNY', label: 'CNY' },
                        { value: 'COP', label: 'COP' },
                        { value: 'CRC', label: 'CRC' },
                        { value: 'CZK', label: 'CZK' },
                        { value: 'DKK', label: 'DKK' },
                        { value: 'DJF', label: 'DJF' },
                        { value: 'DOP', label: 'DOP' },
                        { value: 'USD', label: 'USD' },
                        { value: 'EGP', label: 'EGP' },
                        { value: 'ERN', label: 'ERN' },
                        { value: 'ETB', label: 'ETB' },
                        { value: 'FJD', label: 'FJD' },
                        { value: 'GMD', label: 'GMD' },
                        { value: 'GEL', label: 'GEL' },
                        { value: 'GHS', label: 'GHS' },
                        { value: 'GTQ', label: 'GTQ' },
                        { value: 'GNF', label: 'GNF' },
                        { value: 'GYD', label: 'GYD' },
                        { value: 'HTG', label: 'HTG' },
                        { value: 'HNL', label: 'HNL' },
                        { value: 'HKD', label: 'HKD' },
                        { value: 'HUF', label: 'HUF' },
                        { value: 'ISK', label: 'ISK' },
                        { value: 'INR', label: 'INR' },
                        { value: 'IDR', label: 'IDR' },
                        { value: 'ILS', label: 'ILS' },
                        { value: 'JMD', label: 'JMD' },
                        { value: 'JPY', label: 'JPY' },
                        { value: 'JOD', label: 'JOD' },
                        { value: 'KZT', label: 'KZT' },
                        { value: 'KES', label: 'KES' },
                        { value: 'KWD', label: 'KWD' },
                        { value: 'KGS', label: 'KGS' },
                        { value: 'LAK', label: 'LAK' },
                        { value: 'LBP', label: 'LBP' },
                        { value: 'LSL', label: 'LSL' },
                        { value: 'LRD', label: 'LRD' },
                        { value: 'CHF', label: 'CHF' },
                        { value: 'MOP', label: 'MOP' },
                        { value: 'MKD', label: 'MKD' },
                        { value: 'MGA', label: 'MGA' },
                        { value: 'MWK', label: 'MWK' },
                        { value: 'MYR', label: 'MYR' },
                        { value: 'MVR', label: 'MVR' },
                        { value: 'MRU', label: 'MRU' },
                        { value: 'MUR', label: 'MUR' },
                        { value: 'MXN', label: 'MXN' },
                        { value: 'MDL', label: 'MDL' },
                        { value: 'MNT', label: 'MNT' },
                        { value: 'MAD', label: 'MAD' },
                        { value: 'MZN', label: 'MZN' },
                        { value: 'MMK', label: 'MMK' },
                        { value: 'NAD', label: 'NAD' },
                        { value: 'NPR', label: 'NPR' },
                        { value: 'NZD', label: 'NZD' },
                        { value: 'NIO', label: 'NIO' },
                        { value: 'NGN', label: 'NGN' },
                        { value: 'NOK', label: 'NOK' },
                        { value: 'OMR', label: 'OMR' },
                        { value: 'PKR', label: 'PKR' },
                        { value: 'PAB', label: 'PAB' },
                        { value: 'PGK', label: 'PGK' },
                        { value: 'PYG', label: 'PYG' },
                        { value: 'PEN', label: 'PEN' },
                        { value: 'PHP', label: 'PHP' },
                        { value: 'PLN', label: 'PLN' },
                        { value: 'QAR', label: 'QAR' },
                        { value: 'RON', label: 'RON' },
                        { value: 'RUB', label: 'RUB' },
                        { value: 'RWF', label: 'RWF' },
                        { value: 'SAR', label: 'SAR' },
                        { value: 'RSD', label: 'RSD' },
                        { value: 'SGD', label: 'SGD' },
                        { value: 'ZAR', label: 'ZAR' },
                        { value: 'KRW', label: 'KRW' },
                        { value: 'SSP', label: 'SSP' },
                        { value: 'LKR', label: 'LKR' },
                        { value: 'SDG', label: 'SDG' },
                        { value: 'SRD', label: 'SRD' },
                        { value: 'SEK', label: 'SEK' },
                        { value: 'SYP', label: 'SYP' },
                        { value: 'TWD', label: 'TWD' },
                        { value: 'TJS', label: 'TJS' },
                        { value: 'TZS', label: 'TZS' },
                        { value: 'THB', label: 'THB' },
                        { value: 'TTD', label: 'TTD' },
                        { value: 'TND', label: 'TND' },
                        { value: 'TRY', label: 'TRY' },
                        { value: 'UGX', label: 'UGX' },
                        { value: 'UAH', label: 'UAH' },
                        { value: 'AED', label: 'AED' },
                        { value: 'UYU', label: 'UYU' },
                        { value: 'UZS', label: 'UZS' },
                        { value: 'VES', label: 'VES' },
                        { value: 'VND', label: 'VND' },
                        { value: 'YER', label: 'YER' },
                        { value: 'ZMW', label: 'ZMW' },
                        { value: 'ZWL', label: 'ZWL' },
                      ]}
                      placeholder={t('placeholders.option')}
                      closeMenuOnSelect={false}
                      size="sm"
                    />
                    <FormErrorMessage>
                      {errors.currencies && errors.currencies.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.settlementCurrencies}>
                    <FormLabel>{t('projects.settlementCurrencies')}:</FormLabel>
                    <Select
                      isMulti
                      id="settlementCurrencies"
                      onChange={handleCurrency}
                      options={[
                        { value: 'USD', label: 'USD' },
                        { value: 'EUR', label: 'EUR' },
                      ]}
                      placeholder={t('placeholders.option')}
                      closeMenuOnSelect={false}
                      size="sm"
                    />
                    <FormErrorMessage>
                      {errors.settlementCurrencies &&
                        errors.settlementCurrencies.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />
              </Box>

              <Box p={3} justifyContent={'end'} display={'flex'}>
                <Button
                  variant="outline"
                  border={'1px solid #982549'}
                  color={'#982549'}
                  onClick={onClose}
                  mx={2}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button isLoading={isSubmitting} type="submit">
                  {t('buttons.confirm')}
                </Button>
              </Box>
            </form>
          </DrawerContent>
        </Drawer>
        <Spacer />
      </Flex>

      <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
        <DataTable
          value={projects}
          paginator
          rows={10}
          emptyMessage="Nenhum registro encontrado"
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`projects.${col.header}`)}
              width={col.width}
              sortable={col.sortable}
              body={col.body}
            />
          ))}
        </DataTable>
      </Box>
    </>
  );
};

export const Projects = () => {
  return (
    <>
      <Flex ms={2} flexDirection="column" px={8}>
        <Breadcrumb
          borderRadius={8}
          bg="white"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          p={2}
          color="#333"
          my={3}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Muevy</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Projects</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box bgColor={'white'} p={6} boxShadow={'md'} minH={'80vh'}>
          <Flex direction="column" pt={{ base: '120px', md: '5px' }}>
            <TableContainer>
              <DataProcess />
            </TableContainer>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
