import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';
import { getProfle } from '../../components/auth/UserData';

export const getProducts = createAsyncThunk('products/get', async () => {
  let response;
  if (getProfle() === 'ACQUIRER') {
    response = await api.get(`/portalapi/product/acquirer`);
  } else {
    response = await api.get(`/portalapi/product/merchant`);
  }
  return response.data;
});

export const getProduct = createAsyncThunk('products/getId', async id => {
  const response = await api.get(`/portalapi/product/${id}`);
  return response.data;
});

export const getProvider = createAsyncThunk('products/provider', async id => {
  const response = await api.get(`/portalapi/provider`);
  return response.data;
});

export const getProviderById = createAsyncThunk(
  'products/provider/id',
  async id => {
    const response = await api.get(`/portalapi/provider/${id}`);
    return response.data;
  }
);

export const registerProduct = createAsyncThunk(
  'products/register',
  async data => {
    try {
      const response = await api.post(`/portalapi/product`, data);
      return response;
    } catch (error) {}
  }
);

export const updateProduct = createAsyncThunk('products/update', async data => {
  try {
    const send = {
      description: data.description,
      destinationCountry: data.destinationCountry.map(dc => dc.value),
      destinationCurrencies: data.destinationCurrencies.map(dc => dc.value),
      settlementCurrencies: data.settlementCurrencies.map(ec => ec.value),
      fees: [],
      limits: [],
    };
    const response = await api.post(
      `/portalapi/product/${data.id}/update`,
      send
    );
    return response;
  } catch (error) {}
});

export const disableProduct = createAsyncThunk('products/disable', async id => {
  try {
    const response = await api.post(`/portalapi/product/${id}/disable`);
    return response.data;
  } catch (error) {}
});

export const enableProduct = createAsyncThunk('products/enable', async id => {
  try {
    const response = await api.post(`/portalapi/product/${id}/enable`);
    return response.data;
  } catch (error) {}
});
