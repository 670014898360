import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { list, useToast } from '@chakra-ui/react';
import {
  Flex,
  Box,
  Text,
  Button,
  PinInputField,
  PinInput,
  HStack,
  Input,
  InputGroup,
  Grid,
  GridItem,
  Select,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Stack,
  VStack,
  StackDivider,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ArrowDownIcon, Search2Icon } from '@chakra-ui/icons';
import colors from '../../../../assets/custom/colors';
import {
  getProducts,
  getProviderById,
} from '../../../../redux/action/products';
import { otp } from '../../../../redux/action/platform';
import { getProfle, getUserId } from '../../../../components/auth/UserData';
import {
  getBalanceByProd,
  getFavRecipient,
  getFavSender,
  quoteUnitary,
} from '../../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import {
  validateBIC,
  validateIBAN,
} from '../../../../components/Validators/AccountNumberValidator';
import { getProjectById } from '../../../../redux/action/projects';

const formatValue = value => {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length === 0) {
    return '0,00';
  }

  const decimalPart = cleanedValue.slice(-2).padStart(2, '0');
  const integerPart = cleanedValue.slice(0, -2).replace(/^0+/, '');
  const formattedIntegerPart = integerPart
    .split('')
    .reverse()
    .reduce((acc, digit, index) => {
      return digit + (index && index % 3 === 0 ? '.' : '') + acc;
    }, '');

  return `${formattedIntegerPart || '0'},${decimalPart}`;
};

export const FormUnitary = ({ sendType, nextStep, backStep, setFile }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [values, setValues] = useState([]);
  const [quote, setQuote] = useState('');
  const [quoteId, setQuoteId] = useState('');
  const [form, setForm] = useState([]);
  const [pin, setPin] = useState('');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [favsRecipient, setFavsRecipient] = useState([]);
  const [onOpenQuote, setOpenQuote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onOpenFavsSender, setOpenFavsSender] = useState(false);
  const [selectFavSender, setSelectedFavSender] = useState('');
  const [onOpenFavsRecipient, setOpenFavsRecipient] = useState(false);
  const [selectFavRecipient, setSelectedFavRecipient] = useState('');
  const [balance, setBalance] = useState([]);
  const [selectedFavs, setSelectedFavs] = useState([]);
  const [favsSender, setFavsSender] = useState([]);
  const [amount, setAmount] = useState('0,00');
  const [searchTerm, setSearchTerm] = useState('');
  const [provider, setProvider] = useState('');
  const [term, setTerm] = useState('');
  const [termRecipient, setTermRecipient] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [senderCountry, setSenderCountry] = useState('');
  const [recipientCountry, setRecipientCountry] = useState('');
  const [error, setError] = useState(false);
  const [validBAN, setValidBAN] = useState(false);
  const [validBIC, setValidBIC] = useState(false);
  const [blockPaymentType, setBlockPaymentType] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [countries, setCountries] = useState([]);
  const [destCurrencies, setDestCurrencies] = useState([]);
  const inputRef = useRef(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const countriesReci = [
    { code: 'USA', name: 'United States' },
    { code: 'CHN', name: 'China' },
    { code: 'RUS', name: 'Russia' },
    { code: 'IND', name: 'India' },
    { code: 'JPN', name: 'Japan' },
    { code: 'DEU', name: 'Germany' },
    { code: 'GBR', name: 'United Kingdom' },
    { code: 'FRA', name: 'France' },
    { code: 'BRA', name: 'Brazil' },
    { code: 'CAN', name: 'Canada' },
    { code: 'AUS', name: 'Australia' },
    { code: 'ITA', name: 'Italy' },
    { code: 'ESP', name: 'Spain' },
    { code: 'KOR', name: 'South Korea' },
    { code: 'MEX', name: 'Mexico' },
    { code: 'IDN', name: 'Indonesia' },
    { code: 'SAU', name: 'Saudi Arabia' },
    { code: 'TUR', name: 'Turkey' },
    { code: 'ARG', name: 'Argentina' },
    { code: 'ZAF', name: 'South Africa' },
    { code: 'EGY', name: 'Egypt' },
    { code: 'NGA', name: 'Nigeria' },
    { code: 'SWE', name: 'Sweden' },
    { code: 'CHE', name: 'Switzerland' },
    { code: 'NLD', name: 'Netherlands' },
    { code: 'POL', name: 'Poland' },
    { code: 'PRT', name: 'Portugal' },
    { code: 'IRL', name: 'Ireland' },
    { code: 'GRC', name: 'Greece' },
    { code: 'AUT', name: 'Austria' },
    { code: 'HUN', name: 'Hungary' },
    { code: 'DNK', name: 'Denmark' },
    { code: 'FIN', name: 'Finland' },
    { code: 'NOR', name: 'Norway' },
    { code: 'BEL', name: 'Belgium' },
    { code: 'LUX', name: 'Luxembourg' },
    { code: 'CZE', name: 'Czech Republic' },
    { code: 'SVK', name: 'Slovakia' },
    { code: 'SVN', name: 'Slovenia' },
    { code: 'HRV', name: 'Croatia' },
    { code: 'ROU', name: 'Romania' },
    { code: 'BGR', name: 'Bulgaria' },
    { code: 'EST', name: 'Estonia' },
    { code: 'LVA', name: 'Latvia' },
    { code: 'LTU', name: 'Lithuania' },
    { code: 'MLT', name: 'Malta' },
    { code: 'ISL', name: 'Iceland' },
    { code: 'NZL', name: 'New Zealand' },
    { code: 'SGP', name: 'Singapore' },
    { code: 'ARE', name: 'United Arab Emirates' },
    { code: 'ISR', name: 'Israel' },
    { code: 'CHL', name: 'Chile' },
    { code: 'URY', name: 'Uruguay' },
    { code: 'COL', name: 'Colombia' },
    { code: 'PER', name: 'Peru' },
    { code: 'VEN', name: 'Venezuela' },
    { code: 'PAK', name: 'Pakistan' },
    { code: 'BGD', name: 'Bangladesh' },
    { code: 'THA', name: 'Thailand' },
    { code: 'VNM', name: 'Vietnam' },
    { code: 'MYS', name: 'Malaysia' },
    { code: 'PHL', name: 'Philippines' },
    { code: 'KAZ', name: 'Kazakhstan' },
    { code: 'UKR', name: 'Ukraine' },
    { code: 'BLR', name: 'Belarus' },
    { code: 'IRN', name: 'Iran' },
    { code: 'IRQ', name: 'Iraq' },
    { code: 'SYR', name: 'Syria' },
    { code: 'AFG', name: 'Afghanistan' },
    { code: 'ETH', name: 'Ethiopia' },
    { code: 'KEN', name: 'Kenya' },
    { code: 'TZA', name: 'Tanzania' },
    { code: 'GHA', name: 'Ghana' },
    { code: 'UGA', name: 'Uganda' },
    { code: 'DZA', name: 'Algeria' },
    { code: 'MAR', name: 'Morocco' },
    { code: 'TUN', name: 'Tunisia' },
  ];

  const columnsFavSender = [
    {
      field: 'firstName',
      header: 'firstName',
      sortable: true,
    },
    {
      field: 'lastName',
      header: 'lastName',
      sortable: true,
    },
    { field: 'address.line', header: 'senderAddressLine', sortable: true },
    {
      field: 'address.country',
      header: 'senderAddressCountry',
      sortable: true,
    },
  ];

  const columnsFavRecipient = [
    {
      field: 'firstName',
      header: 'firstName',
      sortable: true,
    },
    {
      field: 'lastName',
      header: 'lastName',
      sortable: true,
    },
    {
      field: 'address.country',
      header: 'recipientCountryCode',
      sortable: true,
    },
    {
      field: 'address.state',
      header: 'recipientState',
      sortable: true,
    },
    {
      field: 'address.city',
      header: 'recipientCity',
      sortable: true,
    },
    {
      field: 'address.line',
      header: 'recipientAddressLine',
      sortable: true,
    },
    {
      field: 'address.postalCode',
      header: 'recipientPostalCode',
      sortable: true,
    },
    {
      field: 'accountNumber',
      header: 'recipientAccountNumber',
      sortable: true,
    },
    { field: 'recipientBankCode', header: 'recipientBankCode', sortable: true },
  ];

  const swiftRequired = ['AUS', 'CAN', 'NZL'];
  const postalRequired = ['AUS', 'USA'];
  const cityRequired = ['AUS', 'USA'];
  const bankCodeRequired = ['AUS', 'CAN', 'NZL'];
  const sepList = [
    'AND',
    'AUT',
    'BEL',
    'BGR',
    'HRV',
    'CYP',
    'CZE',
    'DNK',
    'EST',
    'FIN',
    'FRA',
    'DEU',
    'GRC',
    'HUN',
    'IRL',
    'ISL',
    'ITA',
    'LVA',
    'LIE',
    'LTU',
    'LUX',
    'MLT',
    'MCO',
    'NLD',
    'NOR',
    'POL',
    'PRT',
    'ROU',
    'SMR',
    'SVK',
    'SVN',
    'ESP',
    'SWE',
    'CHE',
    'VAT',
  ];

  useEffect(() => {
    const products = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setProducts(data);
      } catch (error) {}
    };
    products();
  }, []);

  useEffect(() => {
    const confirm = async () => {
      try {
        if (product) {
          const data = await dispatch(getBalanceByProd(product)).unwrap();
          setBalance(data.accounts);
        }
      } catch (error) {}
    };
    confirm();
  }, [confirm]);

  const handleChange = event => {
    const input = event.target;
    let inputValue = input.value.replace(/[^\d,]/g, '');
    if (inputValue.length > 8) {
      inputValue = inputValue.slice(0, 8);
    }

    const formattedValue = formatValue(inputValue);
    setAmount(formattedValue);

    if (formattedValue !== '0,00') {
      setError(false);
    }

    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          formattedValue.length;
      }
    });
  };

  const handleStep = async e => {
    const data = {
      ...values,
      productId: product,
      otpToken: pin,
      quoteId: quoteId,
      provider: provider,
      amount: Number(parseFloat(amount.replace(/\./g, '').replace(',', '.'))),
    };
    nextStep(data);
  };

  const handleBlur = () => {
    if (amount === '0,00' || amount.trim() === '') {
      setError(true);
    }
  };

  const handleOpenFav = async e => {
    setOpenFavsSender(true);
  };

  const handleFavsClose = async e => {
    setSearchTerm('');
    setOpenFavsSender(false);
  };

  const handleConfirmFavSender = async e => {
    reset({
      senderFirstName: selectFavSender.firstName,
      senderLastName: selectFavSender.lastName,
      senderAddressLine: selectFavSender.address.line,
      senderAddressCountry: selectFavSender.address.country,
    });
    setTerm('');
    setSelectedFavSender([]);
    setOpenFavsSender(false);
  };

  const handleSearchFavSender = async e => {
    let filter = {
      productId: product,
      term: term,
    };
    const data = await dispatch(getFavSender(filter)).unwrap();
    setFavsSender(data);
  };

  const handleOpenFavReci = e => {
    setOpenFavsRecipient(true);
  };

  const handleFavsReciClose = async e => {
    setSearchTerm('');
    setOpenFavsRecipient(false);
  };

  const handleSearchFavReci = async e => {
    let filter = {
      productId: product,
      term: termRecipient,
    };
    const data = await dispatch(getFavRecipient(filter)).unwrap();
    setFavsRecipient(data);
  };

  const handleConfirmFavReci = async e => {
    reset({
      recipientFirstName: selectFavRecipient.firstName,
      recipientLastName: selectFavRecipient.lastName,
      recipientCountryCode: selectFavRecipient.address.country,
      recipientState: selectFavRecipient.address.state,
      recipientCity: selectFavRecipient.address.city,
      recipientAddressLine: selectFavRecipient.address.line,
      recipientPostalCode: selectFavRecipient.address.postalCode,
      recipientAccountNumber: selectFavRecipient.accountNumber,
      recipientBankCode: selectFavRecipient.bankCode,
    });
    setTermRecipient('');
    setSelectedFavRecipient([]);
    setOpenFavsRecipient(false);
  };

  const handleCancel = async => {
    backStep();
  };

  const handleCloseOtp = async => {
    setPin('');
    onClose();
  };

  const handleConfirm = async => {
    setConfirm(true);
  };

  const handleSelectChange = async e => {
    setProduct(e.target.value);
    let prod = products.filter(f => f.id === e.target.value);
    let proj = {};
    if (prod[0].projectId !== null) {
      proj = await dispatch(getProjectById(prod[0].projectId)).unwrap();
    }
    const data = await dispatch(getProviderById(proj.providerId)).unwrap();
    setProvider(data);
    const listCountries = [];
    const listCurrencies = [];
    prod[0].destinationCountry.map((m, index) => {
      let c = {
        code: m,
        name: m,
      };
      listCountries[index] = c;
    });
    prod[0].destinationCurrencies.map((m, index) => {
      let c = {
        code: m,
        name: m,
      };
      listCurrencies[index] = c;
    });
    setCountries(listCountries);
    setDestCurrencies(listCurrencies);
  };

  const formattedAmount = (amount, currency) => {
    if (quote !== '') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: currency,
      }).format(parseFloat(amount));
    }
  };

  const handleForm = async e => {
    if (sepList.includes(selectedCountry) || selectedCountry === 'GBR') {
      e.recipientAccountNumber = `iban:${e.recipientAccountNumber}`;
    } else if (selectedCountry === 'USA') {
      e.recipientAccountNumber = `ban:${e.recipientAccountNumber};bic=${e.routingNumber}`;
    } else if (selectedCountry === 'CHN') {
      e.recipientAccountNumber = `ban:${e.recipientAccountNumber};bic=${e.recipientBankCode}`;
    } else {
      e.recipientAccountNumber = `ban:${e.recipientAccountNumber};bic=${e.codeSwift}`;
    }
    setForm(e);
    if (getProfle() === 'ACQUIRER') {
      let data = {
        productId: product,
        senderAccount:
          provider.abbreviation === 'MASTERCARD'
            ? 'ewallet:474709;sp=ibcorretora'
            : '3487790146614',
        recipientAccount: e.recipientAccountNumber,
        paymentType: e.paymentType,
        destinationAmount: Number(
          parseFloat(amount.replace(/\./g, '').replace(',', '.'))
        ),
        destinationCountryCode: e.recipientCountryCode,
        destinationCurrencyCode: e.recipientCurrencyCode,
      };
      const q = await dispatch(quoteUnitary(data)).unwrap();
      if (q === undefined) {
        toast({
          title: t(`toast.quoteError`),
          position: 'top',
          description: t(`toast.quoteErrorDesc`),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      } else if (q?.errors && q?.errors[0]?.code === 'QTE-001') {
        setValues(e);
        toast({
          title: t(`toast.quoteFx`),
          position: 'top',
          description: '',
          status: 'info',
          duration: 4000,
          isClosable: true,
        });
        let data = {
          userId: getUserId(),
          transaction: 'OCT',
        };
        dispatch(otp(data));
        setOpenQuote(false);
        setQuote('');
        onOpen();
      } else {
        setQuote(q);
        setQuoteId(q.quoteId);
        setOpenQuote(true);
      }
    } else {
      const data = {
        ...e,
        productId: product,
        quoteId: '',
        amount: Number(parseFloat(amount.replace(/\./g, '').replace(',', '.'))),
      };
      nextStep(data);
    }
  };

  const handleCloseQuote = async e => {
    setOpenQuote(false);
  };

  const checkPaymentType = async e => {
    setPaymentType(e.target.value);
  };

  const validateAccountNumber = async e => {
    const result = validateIBAN(selectedCountry, e.target.value);
    if (!result) {
      toast({
        title: t(`toast.accountNumber`),
        position: 'top',
        description: t(`toast.invalidBanDesc`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setValidBAN(false);
      setDisabled(false);
    } else {
      toast({
        title: t(`toast.accountNumber`),
        position: 'top',
        description: t(`toast.validBanDesc`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setValidBAN(true);
      if (swiftRequired.includes(selectedCountry)) {
        if (validBAN && validBIC) {
          setDisabled(true);
        }
      } else {
        setDisabled(true);
      }
    }
  };

  const validateBicNumber = async e => {
    const result = validateBIC(selectedCountry, e.target.value);
    if (!result) {
      toast({
        title: t(`toast.codeSwift`),
        position: 'top',
        description: t(`toast.invalidBicDesc`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setValidBIC(false);
      setDisabled(false);
    } else {
      toast({
        title: t(`toast.codeSwift`),
        position: 'top',
        description: t(`toast.validBicDesc`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setValidBIC(true);
      if (validBAN) {
        setDisabled(true);
      }
    }
  };

  const changeReciCountry = async e => {
    setSelectedCountry(e.target.value);
    if (e.target.value == 'ARG') {
      setDisabled(true);
      setValidBIC(true);
    } else if (e.target.value == 'CHN') {
      setPaymentType('B2B');
      setBlockPaymentType(true);
      reset({
        paymentType: 'B2B',
      });
    } else {
      setBlockPaymentType(false);
    }
  };

  const validateSenderCountry = async e => {
    setSenderCountry(e.target.value);
  };

  const validateRecipientCountry = async e => {
    setRecipientCountry(e.target.value);
  };

  const handleConfirmQuote = async e => {
    setValues(form);
    let data = {
      userId: getUserId(),
      transaction: 'OCT',
    };
    dispatch(otp(data));
    setOpenQuote(false);
    setQuote('');
    onOpen();
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      display={'flex'}
      minW={'60vw'}
    >
      <Text color={'black'} fontSize="2xl">
        {t('octUnitary.formUnitary.title')}
        <Divider borderColor={'black'} />
      </Text>
      <Text color={'#7e7477'} fontSize="md" my={5}>
        {t('octUnitary.formUnitary.subTitle')}
        <Divider borderColor={'#7e7477'} />
      </Text>

      {!confirm && (
        <Flex
          borderRadius={10}
          p={10}
          direction="column"
          pt={{ base: '120px', md: '25px' }}
          boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
        >
          <Box
            my={5}
            width={'100%'}
            boxShadow="lg"
            border={'1px solid #982549'}
            borderRadius={10}
            p={10}
            justifyItems={'center'}
          >
            {products.length > 0 && (
              <>
                <Text justifySelf={'start'} mb={3}>
                  {t('labels.product')}:
                </Text>
                <Select
                  placeholder={t('placeholders.product')}
                  onChange={handleSelectChange}
                >
                  {products
                    ? products.map(option => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.description}
                          </option>
                        );
                      })
                    : ''}
                </Select>
              </>
            )}

            {products.length === 0 && (
              <>
                <Spinner
                  mx={12}
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="red.500"
                  size="xl"
                />
              </>
            )}
          </Box>
          <Box direction="row" justifyContent={'center'} display={'flex'}>
            <Button
              bgColor={'white'}
              color={colors.primary}
              fontSize={15}
              boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              onClick={backStep}
              mx={4}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              onClick={handleConfirm}
              isDisabled={product === '' ? true : false}
            >
              {t('buttons.confirm')}
            </Button>
          </Box>
        </Flex>
      )}

      {confirm && product !== '' && (
        <>
          <Box
            pt={{ base: '120px', md: '25px' }}
            boxShadow="lg"
            borderRadius={10}
            p={8}
            maxW={'60vw'}
            border={'1px solid black'}
          >
            <Flex
              my={3}
              direction={'row'}
              justifyContent={'space-around'}
              alignItems={'flex-start'}
            >
              {balance?.map(m => {
                const getCurrencySymbol = currency => {
                  switch (currency) {
                    case 'USD':
                      return '$';
                    case 'EUR':
                      return '€';
                    default:
                      return currency;
                  }
                };
                const formatAmount = amount => {
                  const amountNumber = parseFloat(amount);
                  return new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(amountNumber);
                };
                const formattedAmount = formatAmount(m.amount);
                return (
                  <Box
                    minW={'14vw'}
                    minH={'8vh'}
                    bgImage={
                      provider.abbreviation === 'MASTERCARD'
                        ? '/img/bg.svg'
                        : '/img/bgBlue.svg'
                    }
                    borderRadius={10}
                    mx={2}
                    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="start"
                  >
                    <Text fontSize="xl" color="white" p={3}>
                      {m.currency}
                    </Text>
                    <Text fontSize="3xl" color="white" as="b" tx p={3}>
                      {getCurrencySymbol(m.currency)} {formattedAmount}
                    </Text>
                  </Box>
                );
              })}
            </Flex>
            <Divider />
            <form onSubmit={handleSubmit(handleForm)}>
              <Text fontSize="2xl" my={5}>
                {t('octUnitary.formUnitary.shippingDetails')}
              </Text>
              <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.recipientCountryCode}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientCountryCode')}
                    </FormLabel>
                    <Select
                      id="recipientCountryCode"
                      {...register('recipientCountryCode', {
                        required: 'Country required',
                      })}
                      placeholder={t('placeholders.recipientCountryCode')}
                      onChange={changeReciCountry}
                    >
                      {countries.map(option => {
                        return (
                          <option value={option.code}>
                            {option.code} - {t(`countryCodes.${option.code}`)}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.recipientCountryCode &&
                        errors.recipientCountryCode.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.recipientCurrencyCode}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientCurrencyCode')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      id="recipientCurrencyCode"
                      placeholder={t('placeholders.recipientCurrencyCode')}
                      {...register('recipientCurrencyCode', {
                        required: 'Currency Code required',
                      })}
                    >
                      {destCurrencies.map(option => {
                        return (
                          <option value={option.code}>{option.code}</option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.recipientCurrencyCode &&
                        errors.recipientCurrencyCode.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={error}>
                    <FormLabel>{t('octUnitary.formUnitary.amount')}</FormLabel>
                    <InputGroup>
                      <Input
                        ref={inputRef}
                        value={amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="0,00"
                        textAlign="right"
                        variant="outline"
                      />
                    </InputGroup>
                    {error && (
                      <FormErrorMessage>Amount required</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.paymentType}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.paymentType')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      placeholder={t('placeholders.paymentType')}
                      {...register('paymentType', {
                        required: 'Payment type required',
                      })}
                      onChange={checkPaymentType}
                      isDisabled={blockPaymentType}
                    >
                      <option value={'B2B'}>B2B</option>
                      <option value={'B2P'}>B2P</option>
                      <option value={'P2P'}>P2P</option>
                      <option value={'P2B'}>P2B</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.paymentType && errors.paymentType.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>
              </Grid>

              <Text fontSize="2xl" my={5}>
                {t('octUnitary.formUnitary.senderDetails')}{' '}
                <Search2Icon
                  boxSize={4}
                  cursor={'pointer'}
                  onClick={handleOpenFav}
                />
              </Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.senderFirstName}>
                    <FormLabel>
                      {paymentType === 'B2B' || paymentType === 'B2P'
                        ? t('merchant.businessName')
                        : t('octUnitary.formUnitary.firstName')}
                    </FormLabel>
                    <Input
                      id="senderFirstName"
                      {...register('senderFirstName', {
                        required: 'Sender first name required',
                      })}
                      placeholder={t('placeholders.senderFirstName')}
                    />
                    <FormErrorMessage>
                      {errors.senderFirstName && errors.senderFirstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {paymentType !== 'B2B' && paymentType !== 'B2P' && (
                  <Box>
                    <FormControl isInvalid={errors.senderLastName}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.lastName')}
                      </FormLabel>
                      <Input
                        id="senderLastName"
                        {...register('senderLastName', {
                          required: 'Sender last name required',
                        })}
                        placeholder={t('placeholders.senderLastName')}
                      />

                      <FormErrorMessage>
                        {errors.senderLastName && errors.senderLastName.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                <Box>
                  <FormControl isInvalid={errors.senderTaxId}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.senderTaxId')}
                    </FormLabel>
                    <Input
                      id="senderTaxId"
                      {...register('senderTaxId', {
                        required: 'Tax Id required',
                      })}
                      placeholder={t('placeholders.senderTaxId')}
                    />
                    <FormErrorMessage>
                      {errors.senderTaxId && errors.senderTaxId.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                <Box>
                  <FormControl isInvalid={errors.senderAddressCountry}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAddressCountry')}
                    </FormLabel>
                    <Select
                      id="senderAddressCountry"
                      {...register('senderAddressCountry', {
                        required: 'Country required',
                      })}
                      placeholder={t('placeholders.senderAddressCountry')}
                      onChange={validateSenderCountry}
                    >
                      {countriesReci.map(option => {
                        return (
                          <option value={option.code}>
                            {option.code} - {t(`countryCodes.${option.code}`)}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.senderAddressCountry &&
                        errors.senderAddressCountry.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.senderCity}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientCity')}
                    </FormLabel>
                    <Input
                      id="senderCity"
                      {...register('senderCity')}
                      placeholder={t('placeholders.recipientCity')}
                    />
                    <FormErrorMessage>
                      {errors.senderCity && errors.senderCity.senderCity}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientState')}
                    </FormLabel>
                    <Input
                      id="senderState"
                      {...register('senderState')}
                      placeholder={t('placeholders.recipientState')}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.senderAddressLine}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAddressLine')}
                    </FormLabel>
                    <Input
                      id="senderAddressLine"
                      {...register('senderAddressLine', {
                        required: 'Address Line required',
                      })}
                      placeholder={t('placeholders.senderAddressLine')}
                    />
                    <FormErrorMessage>
                      {errors.senderAddressLine &&
                        errors.senderAddressLine.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {senderCountry === 'USA' && (
                  <>
                    <GridItem colSpan={3} borderColor="#dae3e9">
                      <Divider />
                    </GridItem>

                    <Box>
                      <FormControl isInvalid={errors.senderState}>
                        <FormLabel>
                          {t('octUnitary.formUnitary.recipientState')}
                        </FormLabel>
                        <Input
                          id="senderState"
                          {...register('senderState')}
                          placeholder={t('placeholders.recipientState')}
                          maxLength={2}
                          onChange={e => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                        />
                        <FormErrorMessage>
                          {errors.senderState && errors.senderState.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box>
                      <FormControl isInvalid={errors.senderPostalCode}>
                        <FormLabel>
                          {t('octUnitary.formUnitary.recipientPostalCode')}
                        </FormLabel>
                        <Input
                          id="senderPostalCode"
                          {...register('senderPostalCode')}
                          placeholder={t('placeholders.recipientPostalCode')}
                        />
                        <FormErrorMessage>
                          {errors.senderPostalCode &&
                            errors.senderState.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </>
                )}

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>
              </Grid>

              <GridItem my={4} colSpan={3} borderColor="#dae3e9">
                <Text fontSize="2xl">
                  {' '}
                  {t('octUnitary.formUnitary.recipientDetails')}{' '}
                  <Search2Icon
                    boxSize={4}
                    cursor={'pointer'}
                    onClick={handleOpenFavReci}
                  />
                </Text>
              </GridItem>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.recipientFirstName}>
                    <FormLabel>
                      {paymentType === 'B2B' || paymentType === 'P2B'
                        ? t('merchant.businessName')
                        : t('octUnitary.formUnitary.firstName')}
                    </FormLabel>
                    <Input
                      id="recipientFirstName"
                      {...register('recipientFirstName', {
                        required: 'Recipient first name required',
                      })}
                      placeholder={t('placeholders.recipientFirstName')}
                    />
                    <FormErrorMessage>
                      {errors.recipientFirstName &&
                        errors.recipientFirstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {(paymentType === 'B2P' ||
                  paymentType === 'P2P' ||
                  paymentType === '') && (
                  <Box>
                    <FormControl isInvalid={errors.recipientLastName}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.lastName')}
                      </FormLabel>
                      <Input
                        id="recipientLastName"
                        {...register('recipientLastName', {
                          required: 'Recipient last name required',
                        })}
                        placeholder={t('placeholders.recipientLastName')}
                      />
                      <FormErrorMessage>
                        {errors.recipientLastName &&
                          errors.recipientLastName.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                {/* {selectedCountry !== 'ARG' && ( */}
                <Box>
                  <FormControl isInvalid={errors.recipientAccountNumber}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientAccountNumber')}
                    </FormLabel>
                    <Input
                      id="recipientAccountNumber"
                      {...register('recipientAccountNumber', {
                        required: 'Recipient account number required',
                      })}
                      placeholder={t('placeholders.recipientAccountNumber')}
                      onBlur={validateAccountNumber}
                      maxLength={32}
                      isDisabled={!selectedCountry}
                    />
                    <FormErrorMessage>
                      {errors.recipientAccountNumber &&
                        errors.recipientAccountNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                {/* )} */}

                {/* {selectedCountry === 'ARG' && (
                  <>
                    <Box>
                      <FormControl isInvalid={errors.governmentIdUri}>
                        <FormLabel>Government ID URI</FormLabel>
                        <Input
                          id="governmentIdUri"
                          {...register('governmentIdUri', {
                            required: 'Government ID URI required',
                          })}
                          placeholder={t('placeholders.value')}
                        />
                        <FormErrorMessage>
                          {errors.governmentIdUri &&
                            errors.governmentIdUri.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </>
                )} */}

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                {swiftRequired.includes(selectedCountry) && (
                  <Box>
                    <FormControl isInvalid={errors.codeSwift}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.codeSwift')}
                      </FormLabel>
                      <Input
                        id="codeSwift"
                        {...register('codeSwift', {
                          required: 'Code Swift required',
                        })}
                        placeholder={t('placeholders.code')}
                        onBlur={validateBicNumber}
                      />
                      <FormErrorMessage>
                        {errors.codeSwift && errors.codeSwift.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                {selectedCountry === 'USA' && (
                  <Box>
                    <FormControl isInvalid={errors.routingNumber}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.routingNumber')}
                      </FormLabel>
                      <Input
                        id="routingNumber"
                        {...register('routingNumber', {
                          required: 'Routing Number required',
                        })}
                        onBlur={validateBicNumber}
                        placeholder="ACH Routing Number"
                      />
                      <FormErrorMessage>
                        {errors.routingNumber && errors.routingNumber.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                {selectedCountry !== 'USA' && (
                  <Box>
                    <FormControl isInvalid={errors.recipientBankCode}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.recipientBankCode')}
                      </FormLabel>
                      <Input
                        id="recipientBankCode"
                        {...register('recipientBankCode', {
                          required: bankCodeRequired.includes(selectedCountry)
                            ? 'Bank Code required'
                            : false,
                        })}
                        placeholder={t('placeholders.recipientBankCode')}
                      />
                      <FormErrorMessage>
                        {errors.recipientBankCode &&
                          errors.recipientBankCode.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                <Box>
                  <FormControl isInvalid={errors.recipientAddressCountry}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAddressCountry')}
                    </FormLabel>
                    <Select
                      id="recipientAddressCountry"
                      {...register('recipientAddressCountry', {
                        required: 'Country required',
                      })}
                      placeholder={t('placeholders.senderAddressCountry')}
                      onChange={validateRecipientCountry}
                    >
                      {countriesReci.map(option => {
                        return (
                          <option value={option.code}>
                            {option.code} - {t(`countryCodes.${option.code}`)}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.recipientAddressCountry &&
                        errors.recipientAddressCountry.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.recipientCity}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientCity')}
                    </FormLabel>
                    <Input
                      id="recipientCity"
                      {...register('recipientCity', {
                        required: cityRequired.includes(selectedCountry)
                          ? 'City required'
                          : false,
                      })}
                      placeholder={t('placeholders.recipientCity')}
                    />
                    <FormErrorMessage>
                      {errors.recipientCity && errors.recipientCity.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {selectedCountry === 'CAN' && (
                  <Box>
                    <FormControl isInvalid={errors.purposePayment}>
                      <FormLabel>
                        {t('octUnitary.formUnitary.purposePayment')}
                      </FormLabel>
                      <Select
                        id="purposePayment"
                        variant="outline"
                        colorScheme="whiteAlpha"
                        placeholder={t('placeholders.option')}
                        {...register('purposePayment', {
                          required: 'Purpose Payment required',
                        })}
                      >
                        <option value="Family Maintenance">
                          Family Maintenance
                        </option>
                        <option value="Household Maintenance">
                          Household Maintenance
                        </option>
                        <option value="Donation or Gifts">
                          Donation or Gifts
                        </option>
                        <option value="Payment of Loan">Payment of Loan</option>
                        <option value="Purchase of Property">
                          Purchase of Property
                        </option>
                        <option value="Funeral Expenses">
                          Funeral Expenses
                        </option>
                        <option value="Medical Expenses">
                          Medical Expenses
                        </option>
                        <option value="Wedding Expenses">
                          Wedding Expenses
                        </option>
                        <option value="Payment of bills">
                          Payment of bills
                        </option>
                        <option value="Education">Education</option>
                        <option value="Savings">Savings</option>
                        <option value="Employee Colleague">
                          Employee Colleague
                        </option>

                        {paymentType !== 'P2P' && (
                          <>
                            <option value="Business/Investment">
                              Business/Investment
                            </option>
                            <option value="Salary">Salary</option>
                            <option value="Payment of goods and services">
                              Payment of goods and services
                            </option>
                          </>
                        )}
                      </Select>
                      <FormErrorMessage>
                        {errors.purposePayment && errors.purposePayment.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                )}

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                <Box>
                  <FormControl isInvalid={errors.recipientAddressLine}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientAddressLine')}
                    </FormLabel>
                    <Input
                      id="recipientAddressLine"
                      {...register('recipientAddressLine', {
                        required: sepList.includes(selectedCountry)
                          ? false
                          : 'Address Line required',
                      })}
                      placeholder={t('placeholders.recipientAddressLine')}
                    />
                    <FormErrorMessage>
                      {errors.recipientAddressLine &&
                        errors.recipientAddressLine.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {recipientCountry === 'USA' && (
                  <>
                    <Box>
                      <FormControl isInvalid={errors.recipientState}>
                        <FormLabel>
                          {t('octUnitary.formUnitary.recipientState')}
                        </FormLabel>
                        <Input
                          id="recipientState"
                          {...register('recipientState', {
                            required:
                              selectedCountry === 'USA'
                                ? 'Recipient State is required'
                                : false,
                          })}
                          placeholder={t('placeholders.recipientState')}
                          maxLength={2}
                          onChange={e => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                        />
                        <FormErrorMessage>
                          {errors.recipientState &&
                            errors.recipientState.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box>
                      <FormControl isInvalid={errors.recipientPostalCode}>
                        <FormLabel>
                          {' '}
                          {t('octUnitary.formUnitary.recipientPostalCode')}
                        </FormLabel>
                        <Input
                          id="recipientPostalCode"
                          {...register('recipientPostalCode', {
                            required: postalRequired.includes(selectedCountry)
                              ? 'Postal code is required'
                              : false,
                          })}
                          placeholder={t('placeholders.recipientPostalCode')}
                        />
                        <FormErrorMessage>
                          {errors.recipientPostalCode &&
                            errors.recipientPostalCode.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </>
                )}

                {selectedCountry === 'CAN' && (
                  <GridItem colSpan={3} borderColor="#dae3e9">
                    <Divider />
                  </GridItem>
                )}

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Text fontSize="2xl">ACAM</Text>
                </GridItem>

                <Box>
                  <FormControl isInvalid={errors.transactionType}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.transactionType')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      placeholder={t('placeholders.option')}
                      {...register('transactionType')}
                    >
                      <option value={'1'}>{t('transactionType.1')}</option>
                      <option value={'2'}>{t('transactionType.2')}</option>
                      <option value={'3'}>{t('transactionType.3')}</option>
                      <option value={'4'}>{t('transactionType.4')}</option>
                      <option value={'5'}>{t('transactionType.5')}</option>
                      <option value={'6'}>{t('transactionType.6')}</option>
                      <option value={'7'}>{t('transactionType.7')}</option>
                      <option value={'8'}>{t('transactionType.8')}</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.transactionType && errors.transactionType.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.paymentMethod}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.paymentMethod')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      placeholder={t('placeholders.option')}
                      {...register('paymentMethod')}
                    >
                      <option value={'1'}>{t('paymentMethod.1')}</option>
                      <option value={'2'}>{t('paymentMethod.2')}</option>
                      <option value={'3'}>{t('paymentMethod.3')}</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.paymentMethod && errors.paymentMethod.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Grid>
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="row"
                py={5}
                borderRadius={10}
                mb={5}
              >
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  border={'1px solid #982549'}
                  onClick={handleCancel}
                  m={3}
                  fontSize={15}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  isLoading={isSubmitting}
                  onClick={handleBlur}
                  isDisabled={!disabled}
                  type="submit"
                >
                  {t('buttons.confirm')}
                </Button>
              </Box>
            </form>

            <Modal isCentered isOpen={isOpen} onClose={handleCloseOtp}>
              <ModalOverlay />
              <ModalContent bgColor="white">
                <ModalHeader>
                  <Text> {t('otp.confirmTransaction')}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text> {t('otp.sureTransaction')}</Text>

                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    my={8}
                  >
                    <HStack>
                      <PinInput value={pin} onChange={value => setPin(value)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                    <Text mt={5} color={'#7e7477'} fontSize={'sm'}>
                      {t('otp.otpSend')}
                    </Text>
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    m={3}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                    onClick={handleCloseOtp}
                  >
                    {t('buttons.cancel')}
                  </Button>
                  <Button isDisabled={!pin} onClick={handleStep}>
                    {t('buttons.confirm')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isCentered isOpen={onOpenQuote} onClose={handleCloseQuote}>
              <ModalOverlay />
              <ModalContent minW={'25vw'} bgColor="white">
                <ModalHeader>
                  <Text> {t('labels.quote')}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    my={8}
                  >
                    <Stack direction="column" spacing={4} align="center">
                      <Box
                        minW="20vw"
                        minH={'10vh'}
                        color="#f7f5f5"
                        border={'1px solid #dae3e9'}
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        boxShadow="lg"
                        mx={5}
                      >
                        <Flex
                          direction={'column'}
                          width={'100%'}
                          alignItems="start"
                        >
                          <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                            {t('quote.amountSend')}
                          </Text>
                          <Divider borderColor={'gray.300'} />

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={'center'}
                          >
                            <Text p={3} color={'black'} fontSize={'2xl'}>
                              {quote !== '' &&
                                formattedAmount(
                                  quote?.destinationAmount,
                                  quote?.destinationCurrencyCode
                                )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>

                      <Box
                        borderRadius={20}
                        bg={'#982549'}
                        display={'flex'}
                        alignContent={'center'}
                      >
                        <Icon
                          color={'white'}
                          m={1}
                          as={ArrowDownIcon}
                          w={4}
                          h={4}
                        />
                      </Box>

                      <Box
                        minW="20vw"
                        minH={'10vh'}
                        borderColor={'gray.400'}
                        border={'1px solid #dae3e9'}
                        color="#f7f5f5"
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent={'space-between'}
                        boxShadow="lg"
                      >
                        <Flex
                          direction={'column'}
                          width={'100%'}
                          alignItems="start"
                        >
                          <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                            {t('quote.amountPaid')}
                          </Text>
                          <Divider borderColor={'gray.300'} />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={'center'}
                          >
                            <Text p={3} color={'black'} fontSize={'2xl'}>
                              {quote &&
                                formattedAmount(
                                  quote?.settlementAmount,
                                  quote?.settlementCurrencyCode
                                )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>

                      <Box
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                      >
                        <VStack
                          divider={<StackDivider borderColor="gray.200" />}
                          spacing={4}
                          align="stretch"
                        >
                          <Box
                            minWidth={'20vw'}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Text>
                              Fx Rate <Icon />
                            </Text>
                            <Text>
                              {quote?.fxRate
                                ? (1 / quote?.fxRate).toFixed(6)
                                : ''}
                            </Text>
                          </Box>
                        </VStack>
                      </Box>
                    </Stack>
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    m={3}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                    onClick={handleCloseQuote}
                  >
                    {t('buttons.cancel')}
                  </Button>
                  <Button onClick={handleConfirmQuote}>
                    {t('buttons.confirm')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* fav sender */}
            <Modal
              size="2xl"
              isCentered
              isOpen={onOpenFavsSender}
              onClose={handleFavsClose}
              blockScrollOnMount={true}
            >
              <ModalOverlay />
              <ModalContent minH={'50vh'} minW={'70vw'} background={'white'}>
                <ModalHeader>{t('favs.favsSender')}</ModalHeader>

                <Box
                  m={3}
                  mb={7}
                  alignContent={'center'}
                  justifyContent={'center'}
                  display={'flex'}
                >
                  <Input
                    justifyContent="space-between"
                    alignItems="start"
                    display="flex"
                    flexDirection="row"
                    overflowWrap="break-word"
                    background="white"
                    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                    borderRadius="15px"
                    p={3}
                    maxW={'50vw'}
                    border={'none'}
                    placeholder={t('favs.filter')}
                    value={term}
                    onChange={e => setTerm(e.target.value)}
                  />

                  <Button
                    colorScheme="blue"
                    ml={3}
                    isLoading={loading}
                    onClick={handleSearchFavSender}
                  >
                    {t('buttons.search')}
                  </Button>
                </Box>

                <Box mx={3} alignContent={'end'} justifyContent={'center'}>
                  <DataTable
                    p={10}
                    value={favsSender}
                    paginator
                    rows={10}
                    selectionMode={true}
                    selection={selectedFavs}
                    dataKey="batchFileTransactionId"
                    emptyMessage="Nenhum registro encontrado"
                    onSelectionChange={e => setSelectedFavSender(e.value)}
                  >
                    <Column
                      selectionMode="single"
                      headerStyle={{ width: '3rem' }}
                    ></Column>
                    {columnsFavSender.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`octUnitary.formUnitary.${col.header}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
                <ModalFooter>
                  <Button
                    onClick={handleFavsClose}
                    mx={2}
                    variant="ghost"
                    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                  >
                    {t('buttons.cancel')}
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    isLoading={loading}
                    onClick={handleConfirmFavSender}
                  >
                    {t('buttons.confirm')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            {/* fav recipient */}
            <Modal
              size="2xl"
              isCentered
              isOpen={onOpenFavsRecipient}
              onClose={handleFavsReciClose}
              blockScrollOnMount={true}
            >
              <ModalOverlay />
              <ModalContent minH={'50vh'} minW={'70vw'} background={'white'}>
                <ModalHeader>{t('favs.favsRecipient')}</ModalHeader>

                <Box
                  m={3}
                  mb={7}
                  alignContent={'center'}
                  justifyContent={'center'}
                  display={'flex'}
                >
                  <Input
                    justifyContent="space-between"
                    alignItems="start"
                    display="flex"
                    flexDirection="row"
                    overflowWrap="break-word"
                    background="white"
                    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                    borderRadius="15px"
                    p={3}
                    maxW={'50vw'}
                    border={'none'}
                    placeholder={t('favs.filter')}
                    value={termRecipient}
                    onChange={e => setTermRecipient(e.target.value)}
                  />

                  <Button
                    colorScheme="blue"
                    ml={3}
                    isLoading={loading}
                    onClick={handleSearchFavReci}
                  >
                    {t('buttons.search')}
                  </Button>
                </Box>

                <Box mx={3} alignContent={'end'} justifyContent={'center'}>
                  <DataTable
                    p={10}
                    value={favsRecipient}
                    paginator
                    rows={10}
                    selectionMode={true}
                    selection={selectedFavs}
                    dataKey="batchFileTransactionId"
                    emptyMessage="Nenhum registro encontrado"
                    tableStyle={{ fontSize: '13px' }}
                    onSelectionChange={e => setSelectedFavRecipient(e.value)}
                  >
                    <Column
                      selectionMode="single"
                      headerStyle={{ width: '3rem' }}
                    ></Column>
                    {columnsFavRecipient.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`octUnitary.formUnitary.${col.header}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
                <ModalFooter>
                  <Button
                    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                    onClick={handleFavsReciClose}
                    mx={2}
                    variant="ghost"
                  >
                    {t('buttons.cancel')}
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    isLoading={loading}
                    onClick={handleConfirmFavReci}
                  >
                    {t('buttons.confirm')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </>
      )}
    </Flex>
  );
};
