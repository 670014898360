import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';

export const registerProject = createAsyncThunk(
  'project/register',
  async data => {
    try {
      const response = await api.post(`/portalapi/project`, data);
      return response;
    } catch (error) {}
  }
);

export const updateProject = createAsyncThunk('project/update', async data => {
  try {

    //update project para receber só os 3 campos
    // const info = {
    //   currencies: data.currencies,
    //   countries: data.countries,
    //   settlementCurrencies: data.elementCurrencies,
    // };
    const response = await api.put(`/portalapi/project/${data.id}`, data);
    return response;
  } catch (error) {}
});

export const inactiveProject = createAsyncThunk(
  'project/inactive',
  async id => {
    try {
      const response = await api.post(`/portalapi/project/${id}/inactive`, []);
      return response;
    } catch (error) {}
  }
);

export const activeProject = createAsyncThunk('project/active', async id => {
  try {
    const response = await api.post(`/portalapi/project/${id}/active`, []);
    return response;
  } catch (error) {}
});

export const getProjects = createAsyncThunk('projects/get', async id => {
  try {
    const response = await api.get(`/portalapi/project`);
    return response.data;
  } catch (error) {}
});

export const getProjectById = createAsyncThunk('projects/get', async id => {
  try {
    const response = await api.get(`/portalapi/project/${id}`);
    return response.data;
  } catch (error) {}
});

export const getProjectByAcquirer = createAsyncThunk('projects/get', async id => {
  try {
    const response = await api.get(`/portalapi/project/acquirer`);
    return response.data;
  } catch (error) {}
});
