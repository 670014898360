import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  Image,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
  },
  bgColor: {
    backgroundColor: '#ffffff',
    flexGrow: 0.99,
  },
  container: {
    margin: 10,
    padding: 10,
  },
  body: {
    backgroundColor: '#dae3e9',
    padding: 20,
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  footer: {
    marginTop: 'auto',
    marginBottom: 5,
    paddingTop: 10,
    borderTopWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    fontSize: 10,
    color: '#333',
  },
  viewData: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: 1,
    marginHorizontal: 10,
  },
  text: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'black',
  },
  image: {
    left: 8,
    width: 70,
    height: 20,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#dae3e9',
    marginTop: 10,
  },
  dividerHeader: {
    height: 1,
    width: '100%',
    backgroundColor: 'black',
    marginTop: 3,
  },
  marginTop: {
    marginTop: 10,
  },
  textInfo: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#212146',
    marginTop: 10,
  },
  textAnswer: {
    fontSize: 9,
    fontWeight: 'normal',
    color: 'black',
    marginTop: 10,
  },
  textHeader: {
    color: '#212146',
    marginTop: 10,
  },
});
const Invoice = ({ data, t, acquirerData, provider }) => (
  <Document>
    <Page size="A4">
      {' '}
      <View style={styles.marginTop}></View>
      <View style={styles.header}>
        <View>
          <Image style={styles.image} src="/img/logo.png" />
        </View>
        <View style={styles.viewData}>
          <Text style={styles.text}>{t('invoice.paymentInvoice')}</Text>
          <Text style={styles.text}>
            {moment(data?.timestamp, 'DD-MM-YYYY HH:mm:ss').format(
              'DD/MM/YYYY HH:mm'
            )}
          </Text>
        </View>
      </View>
      <View style={styles.dividerHeader} />
      <View style={styles.body}>
        <View style={styles.bgColor}>
          <View style={styles.container}>
            <Text style={styles.textHeader}>
              {t(`octBatch.transactionDetails`)}
            </Text>

            <Text style={styles.textInfo}>{t(`invoice.sender`)}</Text>

            <Text style={styles.textAnswer}>
              {data.nameSender} {data.lastNameSender} - {data.addressLineSender}{' '}
              {data.addressCountrySender}
            </Text>

            <Text style={styles.textInfo}>{t(`invoice.receiver`)}</Text>

            <Text style={styles.textAnswer}>
              {data.fullNameRecipient} - {data.addressLineRecipient}{' '}
              {data.addressCityRecipient} {data.addressPostalCodeRecipient}{' '}
              {data.addressCountryRecipient}
            </Text>

            <Text style={styles.textInfo}>
              {t(`invoice.transactionReference`)}
            </Text>

            <Text style={styles.textAnswer}>
              {provider == 'VISA' ? data.paymentId : data.quoteId}
            </Text>

            <Text style={styles.textInfo}>
              {t(`invoice.bankOperationCode`)}
            </Text>

            <Text style={styles.textAnswer}>CRED</Text>

            <Text style={styles.textInfo}>{t(`invoice.32a`)}</Text>

            <Text style={styles.textAnswer}>
              {t(`octBatch.date`)}:{' '}
              {moment(data?.timestamp, 'DD-MM-YYYY HH:mm:ss').format(
                'DD/MM/YYYY'
              )}{' '}
              - {t(`octUnitary.formUnitary.currency`)}:{' '}
              {data?.settlementCurrencyCode} {data.currencyCodeRecipient} -{' '}
              {t(`octUnitary.formUnitary.amount`)}: {data.settlementAmount}
            </Text>

            <Text style={styles.textInfo}>{t(`invoice.50k`)}</Text>

            <Text style={styles.textAnswer}>
              {t(`dashboard.name`)}: {data.nameSender} {data.lastNameSender} -{' '}
              {t(`dashboard.addressLine`)}: {data.addressLineSender} -{' '}
              {data.addressCountrySender}
            </Text>

            <Text style={styles.textInfo}>{t(`invoice.52d`)}</Text>

            <Text style={styles.textAnswer}>
              {t(`dashboard.name`)}: {acquirerData.name} -{' '}
              {t(`dashboard.addressLine`)}: {acquirerData.address}
            </Text>

            <Text style={styles.textInfo}>{t(`invoice.59`)}</Text>

            <Text style={styles.textAnswer}>
              {t(`invoice.account`)}: {data?.accountNumberRecipient} -{' '}
              {data?.addressCountryRecipient === 'USA' ? (
                <Text style={styles.textAnswer}>
                  BIC/ABA: {data.bankCodeRecipient}
                </Text>
              ) : (
                ''
              )}
            </Text>

            <Text style={styles.textAnswer}>
              {t(`dashboard.name`)}: {data.firstNameRecipient}{' '}
              {data.lastNameRecipient} - {t(`dashboard.addressLine`)}:{' '}
              {data.addressLineRecipient}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>{t(`invoice.footerDesc`)}</Text>
        <Text>atendimento@muevy.com</Text>
      </View>
    </Page>
  </Document>
);
export default Invoice;
