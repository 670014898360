import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Text, Button, Spinner, Divider } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { registerOct, registerRequest } from '../../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import { getProfle } from '../../../../components/auth/UserData';

export const OverViewUnitary = ({ values, newTransaction, restartStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [processed, setProcessed] = useState(false);
  const [send, setSend] = useState(false);
  const [errorDesc, setError] = useState('');

  useEffect(() => {
    if (!processed && !send) {
      sendTransaction();
    }
  }, [processed, send]);

  const sendTransaction = useCallback(async () => {
    if (!processed) {
      if (getProfle() === 'ACQUIRER') {
        let req = await dispatch(registerOct(values)).unwrap();
        if (req?.status === 200) {
          setProcessed(true);
          setSend(true);
        } else if (req?.status === 400) {
          setProcessed(true);
          setSend(false);
          if (req.response.data?.errors) {
            setError(req.response.data.errors[0].code);
          } else {
            setError('default');
          }
        }
      } else {
        let req = await dispatch(registerRequest(values)).unwrap();
        if (req?.status === 200) {
          setProcessed(true);
          setSend(true);
        } else if (req?.status === 400) {
          setProcessed(true);
          setSend(false);
          if (req.response.data?.errors) {
            setError(req.response.data.errors[0].code);
          } else {
            setError('default');
          }
        }
      }
    }
  });

  const handleNavigate = e => {
    restartStep();
  };

  return (
    <>
      {!processed && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          boxShadow="lg"
          minH={'30vh'}
          bgColor={'white'}
          border={'1px solid'}
          borderColor={'#7e7477'}
          borderRadius={10}
          p={5}
          mt={10}
        >
          <Text color={'black'} fontSize="2xl">
            {t('octUnitary.overView.processing')}
          </Text>
          <Box mt={3}>
            <Spinner
              thickness="7px"
              speed="0.85s"
              emptyColor="gray.200"
              color="red.500"
              size="xl"
              borderRadius="100"
            />
          </Box>
          <Box
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            borderRadius={10}
            maxW={'20vw'}
            mt={5}
            boxShadow="lg"
            p={5}
          >
            <Text>{t('octUnitary.overView.overDesc')}</Text>
          </Box>
        </Flex>
      )}
      {processed && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={5}
        >
          <Flex
            direction="column"
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            justifyContent="center"
            alignItems="center"
            p={32}
            borderRadius={10}
            minW={'63vw'}
          >
            <Text color={'black'} fontSize="2xl">
              {t('octUnitary.overView.transaction')}{' '}
              {send
                ? t('octUnitary.overView.processed')
                : t('octUnitary.overView.failed')}
              <Divider borderColor={'black'} />
            </Text>

            <Text color={'#7e7477'} fontSize="md" my={5}>
              {send
                ? getProfle() == 'ACQUIRER'
                  ? t('octUnitary.overView.processedDesc')
                  : t('octUnitary.overView.processedDescMerchant')
                : t(`errors.${errorDesc}`)}

              <Divider borderColor={'#7e7477'} />
            </Text>
            {send ? (
              <CheckIcon boxSize={16} m={6} color="green.500" />
            ) : (
              <CloseIcon boxSize={16} m={6} color="red.500" />
            )}
            <Box>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                m={3}
                fontSize={15}
                onClick={handleNavigate}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              >
                {t('octUnitary.overView.newTransaction')}
              </Button>

              {getProfle() === 'ACQUIRER' && (
                <Button onClick={() => navigate('/')} mx={3} type="submit">
                  {t('octUnitary.overView.transactions')}
                </Button>
              )}

              {getProfle() === 'MERCHANT' && (
                <Button
                  onClick={() => navigate('/acquirer/requests')}
                  mx={3}
                  type="submit"
                >
                  {t('sidebar.requestsOCT')}
                </Button>
              )}
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};
