import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Divider,
  Image,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Tooltip,
} from '@chakra-ui/react';
import {
  FiUsers,
  FiBox,
  FiLink,
  FiLogIn,
  FiShoppingCart,
} from 'react-icons/fi';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HamburgerIcon,
  AttachmentIcon
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { getProfle } from '../../components/auth/UserData';
import { useTranslation } from 'react-i18next';

const LinkItemsMuevy = [
  {
    title: 'Perfil Admin',
    name: 'acquirer',
    href: '/muevy/acquirers',
    icon: FiBox,
  },
  { name: 'user', href: '/muevy/users', icon: FiUsers },
  { name: 'projects', href: '/muevy/projects', icon: AttachmentIcon },
];

const LinkItemsAcquirer = [
  {
    title: 'Perfil Acquirer',
    name: 'merchants',
    href: '/acquirer/merchants',
    icon: FiLink,
  },
  {
    name: 'oct',
    href: '/acquirer/octunitary',
    icon: FiLogIn,
    childrens: [
      { name: 'sendBatchOCT', href: '/acquirer/oct' },
      { name: 'sendUnitaryOCT', href: '/acquirer/octunitary' },
      { name: 'requestsOCT', href: '/acquirer/requests' },
    ],
  },
  {
    name: 'product',
    href: '/acquirer/products',
    icon: FiShoppingCart,
    childrens: [
      { name: 'subProduct', href: '/acquirer/products' },
      { name: 'webhooks', href: '/acquirer/product/webhook' },
    ],
  },

  { name: 'user', href: '/muevy/users', icon: FiUsers },
];

const LinkItemsMerchant = [
  {
    name: 'oct',
    icon: FiLogIn,
    childrens: [
      { name: 'sendUnitaryOCT', href: '/acquirer/octunitary' },
      { name: 'requestsOCT', href: '/acquirer/requests' },
    ],
  },
];

const Nav = ({ children, onClick, isOpen, hasChildren, ...rest }) => {
  return (
    <Flex
      align="center"
      my={4}
      borderRadius="lg"
      cursor="pointer"
      onClick={() => (isOpen = false)}
      {...rest}
      _hover={{
        bgColor: '#d6dcdc',
        borderRadius: 8,
      }}
    >
      <Text p="2" fontSize={15}>
        {children}
      </Text>
      {hasChildren && (
        <Icon
          as={isOpen ? ChevronDownIcon : ChevronRightIcon}
          variant="unstyled"
          ml="auto"
          aria-label="Expand menu"
        />
      )}
    </Flex>
  );
};

const NavSub = ({ children, ...rest }) => {
  return (
    <Flex
      align="center"
      p="2"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      {...rest}
      // _hover={{
      //   bgColor: '#d49cac',
      //   borderRadius: 8,
      // }}
    >
      <Text as="u" color="black" fontSize={13}>
        {children}
      </Text>
    </Flex>
  );
};

const SidebarContent = ({ onClose, linkItems, ...rest }) => {
  const navigate = useNavigate();
  const [openIndexes, setOpenIndexes] = useState([]);
  const { t } = useTranslation();
  const handleToggle = index => {
    setOpenIndexes(prevOpenIndexes =>
      prevOpenIndexes.includes(index)
        ? prevOpenIndexes.filter(i => i !== index)
        : [...prevOpenIndexes, index]
    );
  };
  const handleNavigate = e => {
    navigate(e);
    onClose();
  };

  return (
    <Box
      transition="3s ease"
      {...rest}
      p={2}
      justifyContent={'center'}
      alignContent={'center'}
    >
      {linkItems.map((link, index) => (
        <>
          <Box
            key={index}
            onClick={() => handleToggle(index)}
            isOpen={openIndexes.includes(index)}
            mx={2}
          >
            <Nav hasChildren={!!link.childrens}>
              <Flex
                direction="row"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Icon
                  color={'black'}
                  as={link.icon}
                  variant="unstyled"
                  boxSize={5}
                  mr={3}
                />
                <Text
                  fontSize="md"
                  cursor={'underline'}
                  onClick={() => handleNavigate(link.href, false)}
                >
                  {t(`sidebar.${link.name}`)}
                </Text>
              </Flex>
            </Nav>
            {openIndexes.includes(index) && link.childrens && (
              <>
                {link.childrens.map((child, i) => (
                  <Flex
                    direction="column"
                    ml={8}
                    onClick={() => handleNavigate(child.href, false)}
                  >
                    <NavSub key={i}>
                      <Text fontSize="md">{t(`sidebar.${child.name}`)}</Text>
                    </NavSub>
                  </Flex>
                ))}
              </>
            )}
          </Box>
        </>
      ))}

      <Box p="1">
        <Divider borderColor={'black'} mt="4" mb="2" />
        <Text fontSize="sm" color="gray.500">
          version 0.0.1
        </Text>
      </Box>
    </Box>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [linkItems, setLinkItems] = React.useState([]);
  const [openIndexes, setOpenIndexes] = useState([]);
  const handleToggle = index => {
    setOpenIndexes(prevOpenIndexes =>
      prevOpenIndexes.includes(index)
        ? prevOpenIndexes.filter(i => i !== index)
        : [...prevOpenIndexes, index]
    );
  };

  useEffect(() => {
    if (getProfle() === 'ADMIN') {
      setLinkItems(LinkItemsMuevy);
    } else if (getProfle() === 'ACQUIRER') {
      setLinkItems(LinkItemsAcquirer);
    } else {
      setLinkItems(LinkItemsMerchant);
    }
  }, []);

  const handleNavigate = e => {
    setOpenIndexes([]);
    navigate(e);
    onClose();
  };

  return (
    <>
      <Flex
        direction={'column'}
        alignItems={'center'}
        justifyContent="space-between"
        p={3}
        maxH="100vh"
      >
        <HamburgerIcon
          boxSize={6}
          color={'#982549'}
          onClick={onOpen}
          cursor={'pointer'}
          m={3}
        ></HamburgerIcon>

        {linkItems.map((link, index) => (
          <Tooltip label={t(`sidebar.${link.name}`)}>
            <Box key={index} my={6} justifyContent={'center'}>
              <Popover>
                <PopoverTrigger>
                  <Icon
                    onClick={() => {
                      if (link.childrens) {
                        handleToggle(index);
                      } else {
                        handleNavigate(link.href);
                      }
                    }}
                    color={'black'}
                    as={link.icon}
                    variant="unstyled"
                    ml="auto"
                    boxSize={4}
                    cursor={'pointer'}
                    _hover={{
                      transform: 'scale(1.2)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                      borderRadius: 'full',
                    }}
                    _focus={{
                      transform: 'scale(1.2)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                      borderRadius: 'full',
                    }}
                  />
                </PopoverTrigger>
                <Flex position="relative" zIndex={1000}>
                  {link.childrens ? (
                    <PopoverContent
                      boxShadow="lg"
                      preventOverflow={false}
                      borderRadius="md"
                      bg="white"
                    >
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight="bold">
                        {t(`sidebar.${link.name}`)}
                      </PopoverHeader>
                      <PopoverBody>
                        <Flex direction="column" gap={2}>
                          {link.childrens?.map((child, i) => (
                            <Text
                              key={i}
                              onClick={() => handleNavigate(child.href, false)}
                              fontSize="md"
                              cursor="pointer"
                              _hover={{
                                color: 'blue.500',
                                textDecoration: 'underline',
                              }}
                            >
                              {t(`sidebar.${child.name}`)}
                            </Text>
                          ))}
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  ) : null}
                </Flex>
              </Popover>
            </Box>
          </Tooltip>
        ))}
      </Flex>
      <Drawer
        size={'xs'}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerContent overflowY="auto" scrollBehavior="inside" p={5}>
          <Flex
            direction={'column'}
            border={'2px solid #9c3454'}
            borderRadius={10}
            height={'100%'}
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              direction={'column'}
            >
              <Image
                my={4}
                borderRadius={'60%'}
                p={2}
                border={'2px solid #982549'}
                cursor={'pointer'}
                onClick={() => navigate('/')}
                src="/img/m_logo.svg"
                style={{ width: '40px', height: '40px' }}
              />
            </Flex>
            <SidebarContent onClose={onClose} linkItems={linkItems} />
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideBar;
